import {
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Empty,
  Form,
  Input,
  Row,
  Select,
  message,
} from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useChangePackage from '../../hooks/useChangePackage';
import { useCreateTeamForm } from '../../hooks/useCreateTeamForm';

type CreateTeamForm = {
  teamName: string;
  packageStart: moment.Moment;
  packageEnd: moment.Moment;
  packageID: number;
};

export const CreateNewTeam = () => {
  const { setSearchPackageQuery, filteredResults } = useChangePackage();
  const { onSubmit } = useCreateTeamForm();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm<CreateTeamForm>();
  const navigate = useNavigate();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          const { id_team: idTeam } = await onSubmit(
            values.teamName,
            values.packageID,
            values.packageStart,
            values.packageEnd
          );
          navigate(`/teams/${idTeam}`);
        } catch (e) {
          message.error('Failed to create team.Please try again later.');
        }
      })
      .catch(() => {});
  };

  return (
    <>
      <Button type="default" className="mt-5" onClick={showDrawer}>
        Create New Team
      </Button>
      <Drawer
        title="Create Team"
        placement="bottom"
        closable={false}
        open={open}
        onClose={onClose}
      >
        <Form form={form}>
          <Row>
            <Col span={6}>
              <Row>Team Name</Row>
              <Divider className="border-t-2 border-black border-solid" />
              <div className="p-3">
                <Form.Item
                  name="teamName"
                  rules={[{ required: true }]}
                  help={false}
                >
                  <Input className="!h-12" placeholder="Team Name" />
                </Form.Item>
              </div>
              <Divider className="border-t-2 border-black border-solid" />
            </Col>
            <Col span={6}>
              <Row>Start Date</Row>
              <Divider className="border-t-2 border-black border-solid" />
              <div className="p-3">
                <Form.Item
                  name="packageStart"
                  rules={[{ required: true }]}
                  help={false}
                >
                  <DatePicker className="w-full !h-12" />
                </Form.Item>
              </div>
              <Divider className="border-t-2 border-black border-solid" />
            </Col>
            <Col span={6}>
              <Row>End Date</Row>
              <Divider className="border-t-2 border-black border-solid" />
              <div className="p-3">
                <Form.Item
                  name="packageEnd"
                  rules={[{ required: true }]}
                  help={false}
                >
                  <DatePicker className="w-full !h-12" />
                </Form.Item>
              </div>
              <Divider className="border-t-2 border-black border-solid" />
            </Col>
            <Col span={6}>
              <Row>Package</Row>
              <Divider className="border-t-2 border-black border-solid" />
              <div className="p-3">
                <Form.Item
                  name="packageID"
                  rules={[{ required: true }]}
                  help={false}
                >
                  <Select
                    className="w-full !h-12"
                    showSearch
                    placeholder="Select a Package"
                    options={filteredResults.map((field) => ({
                      value: field.value,
                      label: (
                        <div className="break-words whitespace-normal">
                          {field.label}
                        </div>
                      ),
                      searchLabel: field.label,
                    }))}
                    notFoundContent={<Empty />}
                    onSearch={setSearchPackageQuery}
                    filterOption={false}
                  />
                </Form.Item>
              </div>
              <Divider className="border-t-2 border-black border-solid" />
            </Col>
          </Row>
          <Row justify="end">
            <Col xl={{ span: 3 }} md={{ span: 5 }} sm={{ span: 8 }}>
              <div className="p-4">
                <Button type="text" className="w-full" onClick={onClose}>
                  Cancel
                </Button>
              </div>
            </Col>
            <Col xl={{ span: 3 }} md={{ span: 5 }} sm={{ span: 8 }}>
              <div className="p-4">
                <Button
                  type="primary"
                  className="w-full"
                  onClick={handleSubmit}
                >
                  Create Team
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};
