export const FEATURE_IDS = {
  downloads: 14,
  profiles: 13,
  vendorConnects: 199,
  researchViews: 187,
  SSO: 304,
} as const;

export const NEW_FEATURE_IDS = {
  researchReportDownload: 315,
};
