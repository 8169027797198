import React, { ReactElement, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Button, message, Space, Tag, Tooltip, Modal, Select } from 'antd';
import {
  CheckCircleOutlined,
  UndoOutlined,
  CloseCircleOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import {
  GatedTrialUser,
  GatedTrialStatus,
} from '@cbinsights/userv2service/userv2service';

import { useUpdateGatedTrialApprovalStatusMutation } from 'client/modules/gated-trial/useUpdateGatedTrialApprovalStatusMutation';

type ActionCellProps = {
  user: GatedTrialUser;
};

export const BASIC_TRIAL_PACKAGE_ID = 9;
const YARDSTIQ_TRIAL_PACKAGE_ID = 60456;

export const PACKAGES_OPTIONS = [
  {
    label: 'Basic Trial',
    value: BASIC_TRIAL_PACKAGE_ID,
  },
  {
    label: 'Yardstiq Trial',
    value: YARDSTIQ_TRIAL_PACKAGE_ID,
  },
];

export const ActionCell = ({ user }: ActionCellProps): ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<number>(
    BASIC_TRIAL_PACKAGE_ID
  );
  const queryClient = useQueryClient();
  const { mutateAsync } = useUpdateGatedTrialApprovalStatusMutation();

  const onApprovalStatusChange = async (status: GatedTrialStatus) => {
    await mutateAsync(
      {
        email: user.email,
        approval_status: status,
        id_package: selectedPackage,
      },
      {
        onError: async (error) =>
          message.error(`Error setting approval status: ${error}`),
      }
    );
    await queryClient.invalidateQueries(['ListGatedTrialUsers']);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleApprove = () => {
    setIsModalOpen(false);
    onApprovalStatusChange(GatedTrialStatus.APPROVED);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOnChange = (selectedOption: number) => {
    setSelectedPackage(selectedOption);
  };

  if (user.approval_status === GatedTrialStatus.DENIED) {
    return (
      <>
        <Space>
          <Tooltip title="Undo Decision">
            <Button
              size="small"
              type="ghost"
              shape="circle"
              icon={<UndoOutlined />}
              onClick={() => onApprovalStatusChange(GatedTrialStatus.PENDING)}
            />
          </Tooltip>
        </Space>
      </>
    );
  }

  if (user.approval_status === GatedTrialStatus.PENDING) {
    return (
      <>
        <Space>
          <Tooltip title="Approve">
            <Button
              size="small"
              type="primary"
              shape="circle"
              icon={<CheckOutlined />}
              onClick={showModal}
              data-test="approve-button"
            />
          </Tooltip>
          <Tooltip title="Deny">
            <Button
              danger
              size="small"
              type="primary"
              shape="circle"
              icon={<CloseOutlined />}
              onClick={() => onApprovalStatusChange(GatedTrialStatus.DENIED)}
              data-test="deny-button"
            />
          </Tooltip>
          <Modal
            title="Select Package"
            visible={isModalOpen}
            onOk={handleApprove}
            onCancel={handleCancel}
            destroyOnClose
            closable
            footer={[
              <Button onClick={handleCancel}>Cancel</Button>,
              <Button type="primary" onClick={handleApprove}>
                Approve
              </Button>,
            ]}
          >
            <Select
              key={user.email}
              defaultValue={selectedPackage}
              style={{ width: '100%' }}
              onChange={handleOnChange}
              options={PACKAGES_OPTIONS}
            />
          </Modal>
        </Space>
      </>
    );
  }

  return <Space />;
};

export const StatusCell = ({
  status = GatedTrialStatus.PENDING,
}: {
  status: GatedTrialStatus;
}): ReactElement => {
  let color = 'blue';
  let icon = null;

  switch (status) {
    case GatedTrialStatus.APPROVED: {
      color = 'green';
      icon = <CheckCircleOutlined />;
      break;
    }
    case GatedTrialStatus.DENIED: {
      color = 'red';
      icon = <CloseCircleOutlined />;
      break;
    }
    default:
  }

  return (
    <Tag color={color} icon={icon}>
      {status}
    </Tag>
  );
};
