import { Button, Form, Popover, Space } from 'antd';

import { AntdInputField } from 'client/modules/common/antDesign/form/fields/AntdInputField';
import { AntdSelectField } from 'client/modules/common/antDesign/form/fields/AntdSelectField';
import { EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { useFormikContext } from 'formik';
import { useListIndustries } from '../service/hooks/useListIndustries';
import {
  MarketFormProps,
  MarketFormValues,
} from '../hooks/useGetMarketDetailsValues';

export const MarketInfo = () => {
  const { setStatus, status, values } = useFormikContext<MarketFormValues>();
  const handleEditTitle = () => {
    setStatus({
      ...status,
      isNameEditable: !status.isNameEditable,
    });
  };

  const { data: industryList, isFetched: isIndustryFetched } =
    useListIndustries({ enabled: false });

  const industries = industryList
    ?.filter((el) => Number(el.id_parent) === 0)
    ?.map((el) => ({
      label: el.name,
      value: Number(el.id),
    }));

  const subIndustries = values[MarketFormProps.Industry]
    ? industryList
        ?.find((el) => el.id === values[MarketFormProps.Industry].value)
        ?.sub_industries?.map((el) => ({
          label: el.name,
          value: Number(el.id),
        }))
    : [];

  return (
    <div className="max-w-2xl">
      {!status[MarketFormProps.IsNew] && (
        <Form.Item>
          <Button type="link" onClick={handleEditTitle}>
            <Space>
              <span>Change Name</span>
              <EditOutlined />
            </Space>
          </Button>
        </Form.Item>
      )}
      <Form.Item
        label={
          <>
            <span>Description</span>
            {values[MarketFormProps.FieldWithChanges][
              MarketFormProps.MarketDescription
            ] && (
              <Popover
                placement="right"
                content={
                  <div
                    className=" w-[500px] overflow-scroll"
                    style={{
                      whiteSpace: 'break-spaces',
                    }}
                  >
                    {
                      values?.publishedValues?.[
                        MarketFormProps.MarketDescription
                      ]
                    }
                  </div>
                }
                title="Published"
                trigger="hover"
              >
                <span className="pl-1 text-cbi-green">
                  <ExclamationCircleOutlined />
                </span>
              </Popover>
            )}
          </>
        }
      >
        <AntdInputField
          textarea
          name={MarketFormProps.MarketDescription}
          placeholder="Description"
          required
          className=" !min-h-[150px]"
        />
      </Form.Item>
      {isIndustryFetched && (
        <>
          <Form.Item
            label={
              <>
                <span>Industry</span>
                {values[MarketFormProps.FieldWithChanges][
                  MarketFormProps.Industry
                ] && (
                  <Popover
                    content={
                      industries.find(
                        (el) =>
                          el.value ===
                          values?.publishedValues?.[MarketFormProps.Industry]
                      )?.label
                    }
                    title="Published"
                    trigger="hover"
                  >
                    <span className="pl-1 text-cbi-green">
                      <ExclamationCircleOutlined />
                    </span>
                  </Popover>
                )}
              </>
            }
          >
            <AntdSelectField
              name={MarketFormProps.Industry}
              onChangeClear={[
                MarketFormProps.SubIndustry1,
                MarketFormProps.SubIndustry2,
              ]}
              options={industries}
              labelInValue
              placeholder="Select an option"
              allowClear
            />
          </Form.Item>
          <Form.Item
            label={
              <>
                <span>Sub Industry 1</span>
                {values[MarketFormProps.FieldWithChanges][
                  MarketFormProps.SubIndustry1
                ] && (
                  <Popover
                    content={
                      values?.publishedValues?.[
                        MarketFormProps.SubIndustry1
                      ] ? (
                        subIndustries.find(
                          (el) =>
                            el.value ===
                            values?.publishedValues?.[
                              MarketFormProps.SubIndustry1
                            ]
                        )?.label
                      ) : (
                        <span>The value was not previously set</span>
                      )
                    }
                    title="Published"
                    trigger="hover"
                  >
                    <span className="pl-1 text-cbi-green">
                      <ExclamationCircleOutlined />
                    </span>
                  </Popover>
                )}
              </>
            }
          >
            <AntdSelectField
              name={MarketFormProps.SubIndustry1}
              options={subIndustries}
              labelInValue
              placeholder="Select an option"
              allowClear
            />
          </Form.Item>
          <Form.Item
            label={
              <>
                <span>Sub Industry 2</span>
                {values[MarketFormProps.FieldWithChanges][
                  MarketFormProps.SubIndustry2
                ] && (
                  <Popover
                    content={
                      values?.publishedValues?.[
                        MarketFormProps.SubIndustry2
                      ] ? (
                        subIndustries.find(
                          (el) =>
                            el.value ===
                            values?.publishedValues?.[
                              MarketFormProps.SubIndustry2
                            ]
                        )?.label
                      ) : (
                        <span>The value was not previously set</span>
                      )
                    }
                    title="Published"
                    trigger="hover"
                  >
                    <span className="pl-1 text-cbi-green">
                      <ExclamationCircleOutlined />
                    </span>
                  </Popover>
                )}
              </>
            }
          >
            <AntdSelectField
              name={MarketFormProps.SubIndustry2}
              options={subIndustries}
              labelInValue
              placeholder="Select an option"
              allowClear
            />
          </Form.Item>
        </>
      )}
    </div>
  );
};
