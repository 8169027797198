import React, { ReactElement, useState } from 'react';
import { GatedTrialStatus } from '@cbinsights/userv2service/userv2service';

import { useTypedFlags } from 'client/component-library/FeatureFlags/hooks/useTypedFlags';
import { PageNavigation } from './components/PageNavigation';
import UserTable from './components/UserTable';
import useListGatedTrialUserQuery, {
  LIMIT,
} from './useListGatedTrialUserQuery';

export const ListUsersPageContent = (): ReactElement => {
  const [nextOffset, setNextOffset] = useState(0);
  const [nextStatusFilter, setNextStatusFilter] = useState([
    GatedTrialStatus.PENDING,
    GatedTrialStatus.DENIED,
  ]);

  const { data, isFetching } = useListGatedTrialUserQuery(
    nextOffset,
    nextStatusFilter
  );

  const from = (data?.offset || 0) + 1;
  const to = from + Math.min(LIMIT, data?.users.length || LIMIT) - 1;

  return (
    <section className="py-4 px-2" data-test="gated-trial">
      <h2 className="text-2xl font-semibold mx-4 mt-2">
        Gated Trial User List
      </h2>
      <PageNavigation
        from={from}
        to={to}
        onPageChange={(offset) =>
          setNextOffset((current) => current + offset * LIMIT)
        }
        isBackDisabled={isFetching || nextOffset === 0}
        isForwardDisabled={isFetching || data?.users.length < LIMIT}
      />
      <UserTable
        data={data?.users}
        currentStatusFilter={data?.statusFilter}
        onUpdateStatusFilter={setNextStatusFilter}
        isLoading={isFetching && !data?.users.length}
      />
    </section>
  );
};

const ListUserPage = (): ReactElement => {
  const { getFlag } = useTypedFlags();
  const hasGatedTrialAdmin = getFlag('gated-trial-admin');

  if (hasGatedTrialAdmin) return <ListUsersPageContent />;
  return null;
};

export default ListUserPage;
