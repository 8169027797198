import React from 'react';
import { Button, Drawer, Table } from 'antd';
import { EditableCell, EditableRow } from '../EditableTable';
import { DataType, useAddNewUsers } from '../../hooks/useAddNewUsers';
import useShowPagination from '../../hooks/useShowPagination';
import UploadUsers from './UploadUsers';

type Props = {
  open: boolean;
  onClose: () => void;
  idTeam?: number;
};

const AddNewUsers = ({ open, onClose, idTeam }: Props) => {
  const {
    defaultColumns,
    handleSave,
    dataSource,
    handleAdd,
    handleClose,
    saveUsers,
    isLoadingCreateUsers,
    pendingItems,
    setDataSource,
    packagesData,
    portals,
  } = useAddNewUsers({ idTeam, onClose });

  const [currentPage, setCurrentPage] = React.useState(0);

  const pageSize = 5;
  const totalPages = Math.ceil(dataSource.length / pageSize);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const addMoreUsers = () => {
    setCurrentPage(totalPages + 1);
    handleAdd();
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        inputType: col.inputType,
        selectOptions: col.selectOptions,
        rules: col.rules,
      }),
    };
  });

  const pagination = useShowPagination({
    data: dataSource,
    config: {
      pageSize,
      current: currentPage,
      onChange: (page: number) => setCurrentPage(page),
    },
  });

  const handleUpdloadUsers = (users: Array<DataType>) => {
    setDataSource(users);
    setCurrentPage(1);
  };

  return (
    <Drawer
      title={
        <div className="flex justify-between items-center">
          <span>Add User/s</span>
          <UploadUsers
            addUsers={handleUpdloadUsers}
            teamId={idTeam}
            packages={packagesData}
            portals={portals}
          />
        </div>
      }
      placement="bottom"
      size="large"
      onClose={handleClose}
      open={open}
      maskClosable={false}
    >
      <div className="mb-30">
        <Table
          pagination={pagination}
          rowClassName={() => 'editable-row'}
          bordered
          columns={columns}
          dataSource={dataSource}
          components={components}
          onRow={(record) => ({
            style: {
              background: record.expired ? '#fff2f0' : '',
            },
          })}
        />
        <Button className="mb-auto" type="link" onClick={addMoreUsers}>
          Add More Users
        </Button>
      </div>
      <div className="flex justify-end fixed bottom-0 right-0 mb-[40px] mr-[40px] bg-white p-2">
        <Button
          type="text"
          onClick={handleClose}
          loading={isLoadingCreateUsers}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={saveUsers}
          loading={isLoadingCreateUsers}
          disabled={pendingItems.length === 0}
        >
          Process User/s
        </Button>
      </div>
    </Drawer>
  );
};

export default AddNewUsers;
