import {
  Address,
  Alias,
  AlternateUrls,
  CbiEntityType,
  DraftStatus,
  GeneralCbiEntityInfo,
  IdType,
} from '@cbinsights/cbientityservice/cbientityservice';
import { DefaultOptionType } from 'antd/lib/select';
import {
  CBIEntityFormModel,
  ReadonlySectionFieldName,
  SectionFieldName,
} from 'client/modules/cbi-entity/components/cbi-entity/CBISectionFormLayout';
import { getUuid } from 'client/modules/common/form/fields/UtilFields';

export const GeneralInfoFieldNames: Readonly<
  {
    Email: 'email';
    FoundedYear: 'foundedYear';
    Status: 'status';
    Ciks: 'ciks';
    Description: 'description';
    PrimaryType: 'primaryType';
    Sector: 'sector';
    Industry: 'industry';
    SubIndustry: 'subIndustry';
    InvestorCategory: 'investorCategory';
    Addresses: 'addresses';
    Aliases: 'aliases';
    AltUrls: 'altUrls';
    ParentCompanies: 'parentCompanies';
    ChildCompanies: 'childCompanies';
    Deletions: 'deletions';
    IsDraftIncorrect: 'IsDraftIncorrect';
    SubmitType: 'submitType';
    SourceData: 'sourceData';
    GroupTags: 'groupTags';
  } & ReadonlySectionFieldName
> = {
  Email: 'email',
  FoundedYear: 'foundedYear',
  Status: 'status',
  Ciks: 'ciks',
  Description: 'description',
  PrimaryType: 'primaryType',
  Sector: 'sector',
  Industry: 'industry',
  SubIndustry: 'subIndustry',
  InvestorCategory: 'investorCategory',
  Addresses: 'addresses',
  Aliases: 'aliases',
  AltUrls: 'altUrls',
  ParentCompanies: 'parentCompanies',
  ChildCompanies: 'childCompanies',
  Deletions: 'deletions',
  SubmitType: 'submitType',
  IsDraftIncorrect: 'IsDraftIncorrect',
  SourceData: 'sourceData',
  GroupTags: 'groupTags',
  ...SectionFieldName,
};

export type GeneralInfoSubmitType =
  | 'COMPANY_TOGGLE'
  | 'INVESTOR_TOGGLE'
  | 'SAVE_AS_DRAFT'
  | 'MOVE_TO_QA'
  | 'DELETE_DRAFTS'
  | 'PUBLISH'
  | 'DUPLICATE'
  | 'UPDATE'; // this will be deprecated on general info v2.

export const SubmitType: Readonly<{
  COMPANY_TOGGLE: 'COMPANY_TOGGLE';
  INVESTOR_TOGGLE: 'INVESTOR_TOGGLE';
  SAVE_AS_DRAFT: 'SAVE_AS_DRAFT';
  MOVE_TO_QA: 'MOVE_TO_QA';
  DELETE_DRAFTS: 'DELETE_DRAFTS';
  PUBLISH: 'PUBLISH';
  DUPLICATE: 'DUPLICATE';
  UPDATE: 'UPDATE'; // this will be deprecated on general info v2.
}> = {
  COMPANY_TOGGLE: 'COMPANY_TOGGLE',
  INVESTOR_TOGGLE: 'INVESTOR_TOGGLE',
  SAVE_AS_DRAFT: 'SAVE_AS_DRAFT',
  MOVE_TO_QA: 'MOVE_TO_QA',
  DELETE_DRAFTS: 'DELETE_DRAFTS',
  PUBLISH: 'PUBLISH',
  DUPLICATE: 'DUPLICATE',
  UPDATE: 'UPDATE', // this will be deprecated on general info v2.
};

export type EntityAddress = {
  isNew?: boolean;
  id: Address['idAddress'];
  name: Address['addressName'];
  street1: Address['street1'];
  street2: Address['street2'];
  zip: Address['zip'];
  phone: Address['phone'];
  isHq: boolean;
  country: null | {
    value: Address['countryInfo']['idCountry'];
    label: Address['countryInfo']['name'];
    idContinent: Address['countryInfo']['idContinent'];
  };
  state: DefaultOptionType | null;
  city: DefaultOptionType | null;
};

export type EntityAlias = Pick<Alias, 'idAlias' | 'alias' | 'aliasType'>;

export type EntityAltUrl = Pick<AlternateUrls, 'idUrl' | 'url'>;

export type EntityDeletions = {
  ciks: GeneralCbiEntityInfo['ciks'];
  aliases: EntityAlias[];
  altUrls: EntityAltUrl[];
  parentCompanies: (DefaultOptionType & {
    type: IdType;
  })[];
  childCompanies: (DefaultOptionType & {
    type: IdType;
  })[];
  addresses: EntityAddress[];
};

export type GroupTag = {
  idTagGroup: number;
  tagGroup: string;
  tags: Tag[];
};

export type Tag = {
  idTag: number;
  tag: string;
};

export type GeneralInfoFormStatus = {
  [GeneralInfoFieldNames.SubmitType]: GeneralInfoSubmitType;
};

export type GeneralInfoFormModel = {
  [GeneralInfoFieldNames.Email]: GeneralCbiEntityInfo['email'];
  [GeneralInfoFieldNames.FoundedYear]: GeneralCbiEntityInfo['foundedYear'];
  [GeneralInfoFieldNames.Status]: DefaultOptionType | null;
  [GeneralInfoFieldNames.Ciks]: GeneralCbiEntityInfo['ciks'];
  [GeneralInfoFieldNames.Description]: GeneralCbiEntityInfo['description'];
  temporalGeneratedDescriptionId?: number;
  [GeneralInfoFieldNames.PrimaryType]: GeneralCbiEntityInfo['idPrimaryCbiEntityType'];
  [GeneralInfoFieldNames.Sector]: DefaultOptionType | null;
  [GeneralInfoFieldNames.Industry]: DefaultOptionType | null;
  [GeneralInfoFieldNames.SubIndustry]: DefaultOptionType | null;
  [GeneralInfoFieldNames.InvestorCategory]:
    | (DefaultOptionType & {
        idType: number;
      })
    | null;
  [GeneralInfoFieldNames.Addresses]: EntityAddress[];
  [GeneralInfoFieldNames.Aliases]: EntityAlias[];
  [GeneralInfoFieldNames.AltUrls]: EntityAltUrl[];
  [GeneralInfoFieldNames.ParentCompanies]: (DefaultOptionType & {
    type: IdType;
  })[];
  [GeneralInfoFieldNames.ChildCompanies]: (DefaultOptionType & {
    type: IdType;
  })[];
  [GeneralInfoFieldNames.Deletions]: EntityDeletions;
  [GeneralInfoFieldNames.IsDraftIncorrect]?: boolean;
  [GeneralInfoFieldNames.SourceData]?: DefaultOptionType;
  [GeneralInfoFieldNames.GroupTags]?: (DefaultOptionType & {
    groupTag: GroupTag;
  })[];
} & CBIEntityFormModel;

export const mapGeneralCbiEntityToForm = (
  entityInfo: GeneralCbiEntityInfo,
  isDuplicate = false
): GeneralInfoFormModel => {
  const entityFormValues = {
    [GeneralInfoFieldNames.IdCbiEntity]: entityInfo.idCbiEntity,
    [GeneralInfoFieldNames.IdEntityDraft]: null,
    [GeneralInfoFieldNames.EntityName]: entityInfo.entityNameInfo?.primaryName,
    [GeneralInfoFieldNames.Url]: entityInfo.urlInfo?.primaryUrl,
    [GeneralInfoFieldNames.Email]: entityInfo.email,
    [GeneralInfoFieldNames.FoundedYear]: entityInfo.foundedYear || undefined,
    [GeneralInfoFieldNames.Status]: entityInfo.status?.idStatus
      ? {
          value: entityInfo.status?.idStatus,
          label: entityInfo.status?.status,
        }
      : null,
    [GeneralInfoFieldNames.Ciks]: entityInfo.ciks,
    [GeneralInfoFieldNames.Description]: entityInfo.description,
    [GeneralInfoFieldNames.PrimaryType]:
      entityInfo.idPrimaryCbiEntityType || CbiEntityType.COMPANY_ENTITY_TYPE,
    [GeneralInfoFieldNames.Sector]: entityInfo.taxonomy?.sector?.idSector
      ? {
          value: entityInfo.taxonomy?.sector?.idSector,
          label: entityInfo.taxonomy?.sector?.name,
        }
      : null,
    [GeneralInfoFieldNames.Industry]: entityInfo.taxonomy?.industry?.idIndustry
      ? {
          value: entityInfo.taxonomy?.industry?.idIndustry,
          label: entityInfo.taxonomy?.industry?.name,
        }
      : null,
    [GeneralInfoFieldNames.SubIndustry]: entityInfo.taxonomy?.subIndustry
      ?.idSubIndustry
      ? {
          value: entityInfo.taxonomy?.subIndustry?.idSubIndustry || undefined,
          label: entityInfo.taxonomy?.subIndustry?.name || undefined,
        }
      : null,
    [GeneralInfoFieldNames.InvestorCategory]: entityInfo.taxonomy
      ?.investorCategory?.idInvestorCategory
      ? {
          value: entityInfo.taxonomy?.investorCategory?.idInvestorCategory,
          label: entityInfo.taxonomy?.investorCategory?.name,
          idType: entityInfo.taxonomy?.investorCategory?.idType,
        }
      : null,
    [GeneralInfoFieldNames.Addresses]: entityInfo.addresses.map((address) => ({
      id: address?.idAddress,
      name: address?.addressName,
      street1: address?.street1,
      street2: address?.street2,
      zip: address?.zip,
      phone: address?.phone,
      isHq: !!address?.hqFlag,
      country: address?.countryInfo?.idCountry
        ? {
            value: address?.countryInfo?.idCountry,
            label: address?.countryInfo?.name,
            idContinent: address?.countryInfo?.idContinent,
          }
        : null,
      state: address?.stateInfo?.idState
        ? {
            value: address?.stateInfo?.idState || undefined,
            label: address?.stateInfo?.name || undefined,
          }
        : null,
      city: address?.cityInfo?.idCity
        ? {
            value: address?.cityInfo?.idCity,
            label: address?.cityInfo?.name,
          }
        : null,
    })),
    [GeneralInfoFieldNames.Aliases]: entityInfo?.entityNameInfo?.aliases.map(
      (alias) => ({
        idAlias: alias?.idAlias,
        alias: alias?.alias,
        aliasType: alias?.aliasType,
      })
    ),
    [GeneralInfoFieldNames.AltUrls]: entityInfo?.urlInfo?.additionalUrls.map(
      (altUrl) => ({
        idUrl: altUrl?.idUrl,
        url: altUrl?.url,
      })
    ),
    [GeneralInfoFieldNames.ParentCompanies]: entityInfo?.parentEntities
      .map((parent) => ({
        value: parent?.idCbiEntity,
        label: parent?.entityNameInfo?.primaryName,
        type: IdType.CBI_ENTITY,
      }))
      .sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      ),
    [GeneralInfoFieldNames.ChildCompanies]: entityInfo?.childEntities
      .map((child) => ({
        value: child?.idCbiEntity,
        label: child?.entityNameInfo?.primaryName,
        type: IdType.CBI_ENTITY,
      }))
      .sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      ),
    [GeneralInfoFieldNames.GroupTags]: entityInfo?.tagGroups
      ? entityInfo?.tagGroups.map((tagGroup) => ({
          value: tagGroup?.idTagGroup,
          label: tagGroup?.tagGroup,
          groupTag: {
            idTagGroup: tagGroup?.idTagGroup,
            tagGroup: tagGroup?.tagGroup,
            tags: tagGroup?.tags.map((tag) => ({
              idTag: tag?.idTag,
              tag: tag?.tag,
            })),
          },
        }))
      : [],
    [GeneralInfoFieldNames.Meta]: {
      isCompany: entityInfo.isCompany,
      isInvestor: entityInfo.isInvestor,
      idCompany: entityInfo.idCompany,
      idInvestor: entityInfo.idInvestor,
      idType: IdType.CBI_ENTITY,
      isNew: false,
      draftVersion: 0,
      draftStatus: DraftStatus.UNKNOWN_STATUS,
    },
    [GeneralInfoFieldNames.Deletions]: {
      [GeneralInfoFieldNames.Ciks]: [],
      [GeneralInfoFieldNames.Aliases]: [],
      [GeneralInfoFieldNames.AltUrls]: [],
      [GeneralInfoFieldNames.ParentCompanies]: [],
      [GeneralInfoFieldNames.ChildCompanies]: [],
      [GeneralInfoFieldNames.Addresses]: [],
    },
    [GeneralInfoFieldNames.IsDraftIncorrect]: undefined,
  };

  // if its a Duplicate clear out values
  if (isDuplicate) {
    entityFormValues[GeneralInfoFieldNames.IdCbiEntity] = 0;
    entityFormValues[GeneralInfoFieldNames.IdEntityDraft] = null;
    entityFormValues[GeneralInfoFieldNames.PrimaryType] =
      CbiEntityType.COMPANY_ENTITY_TYPE;
    entityFormValues[GeneralInfoFieldNames.Url] = '';
    entityFormValues[GeneralInfoFieldNames.Email] = '';
    entityFormValues[GeneralInfoFieldNames.Ciks] = [];
    entityFormValues[GeneralInfoFieldNames.FoundedYear] = undefined;
    entityFormValues[GeneralInfoFieldNames.ParentCompanies] = [];
    entityFormValues[GeneralInfoFieldNames.ChildCompanies] = [];
    entityFormValues[GeneralInfoFieldNames.Aliases] = [];
    entityFormValues[GeneralInfoFieldNames.AltUrls] = [];
    entityFormValues[GeneralInfoFieldNames.Industry] = null;
    entityFormValues[GeneralInfoFieldNames.SubIndustry] = null;
    entityFormValues[GeneralInfoFieldNames.InvestorCategory] = null;
    entityFormValues[GeneralInfoFieldNames.GroupTags] = [];
    entityFormValues[GeneralInfoFieldNames.Addresses] =
      entityFormValues.addresses.map((address) => ({
        ...address,
        id: getUuid(),
      }));
    entityFormValues[GeneralInfoFieldNames.Meta] = {
      isCompany: false,
      isInvestor: false,
      idCompany: 0,
      idInvestor: 0,
      isNew: true,
      idType: IdType.CBI_ENTITY,
      draftVersion: 0,
      draftStatus: DraftStatus.UNKNOWN_STATUS,
    };
  }

  return entityFormValues;
};
