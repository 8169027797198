import React from 'react';
import { RouteObject } from 'react-router-dom';

const ExternalApiPage = React.lazy(
  () => import('./components/ExternalApiPage')
);

export const externalApiRoutes: RouteObject = {
  path: 'external-api',
  element: (
    <React.Suspense fallback={<></>}>
      <ExternalApiPage />
    </React.Suspense>
  ),
};
