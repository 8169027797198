export const MARKET_REPORTS_PERMISSIONS = {
  pageId: 69,
  name: 'Market Reports',
  path: 'market-reports',
  sections: {
    1: {
      name: 'Edit Reports',
      sectionId: 9,
      path: 'market-reports',
    },
  },
} as const;
