// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.1
//   protoc               v5.27.2
// source: cbinsights/cbientityservice/cbientityservice.proto

/* eslint-disable */

export const protobufPackage = "cbientityservice";

/** version 18.31.0 */

export enum OldPrivateEntityType {
  UNKNOWN_PRIVATE_ENTITY_TYPE = "UNKNOWN_PRIVATE_ENTITY_TYPE",
  PRIVATE_COMPANY = "PRIVATE_COMPANY",
  PRIVATE_INVESTOR = "PRIVATE_INVESTOR",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum CompetitorSource {
  UNKNOWN_COMPETITOR_SOURCE = "UNKNOWN_COMPETITOR_SOURCE",
  SOURCE_ANALYST_BRIEFING = "SOURCE_ANALYST_BRIEFING",
  SOURCE_MARKET_MAP = "SOURCE_MARKET_MAP",
  SOURCE_ADMIN = "SOURCE_ADMIN",
  SOURCE_MODEL_GENERATED = "SOURCE_MODEL_GENERATED",
  SOURCE_PRESUMED_ADMIN = "SOURCE_PRESUMED_ADMIN",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum IdType {
  UNKNOWN = "UNKNOWN",
  COMPANY = "COMPANY",
  INVESTOR = "INVESTOR",
  CBI_ENTITY = "CBI_ENTITY",
  TEAM_ENTITY = "TEAM_ENTITY",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum IdAliasType {
  UNKNOWN_ALIAS_TYPE = "UNKNOWN_ALIAS_TYPE",
  DBA = "DBA",
  FKA = "FKA",
  LEGAL_NAME = "LEGAL_NAME",
  NATIVE_LANGUAGE_NAME = "NATIVE_LANGUAGE_NAME",
  PRODUCT = "PRODUCT",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum CbiEntityType {
  UNKNOWN_CBI_ENTITY_TYPE = "UNKNOWN_CBI_ENTITY_TYPE",
  COMPANY_ENTITY_TYPE = "COMPANY_ENTITY_TYPE",
  INVESTOR_ENTITY_TYPE = "INVESTOR_ENTITY_TYPE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum SortOrder {
  SortOrder_NONE = "SortOrder_NONE",
  SortOrder_DESCENDING = "SortOrder_DESCENDING",
  SortOrder_ASCENDING = "SortOrder_ASCENDING",
  UNRECOGNIZED = "UNRECOGNIZED",
}

/** The enum should match the ids in the database */
export enum SourceType {
  UNKNOWN_SOURCE_TYPE = "UNKNOWN_SOURCE_TYPE",
  INVESTOR_PROFILE = "INVESTOR_PROFILE",
  BUSINESS_RELATIONSHIP = "BUSINESS_RELATIONSHIP",
  TOP_SEARCH = "TOP_SEARCH",
  DATA_REQUEST = "DATA_REQUEST",
  CLIENT_REQUEST = "CLIENT_REQUEST",
  REVENUE = "REVENUE",
  SERVICE_PROVIDER = "SERVICE_PROVIDER",
  BR_PLAYER_ID = "BR_PLAYER_ID",
  QUALTRICS_JOB = "QUALTRICS_JOB",
  GEO_COVERAGE = "GEO_COVERAGE",
  PERSON_ADMIN = "PERSON_ADMIN",
  CHROME_EXTENSION = "CHROME_EXTENSION",
  TOP_SEARCH_CBI_EMPLOYEE = "TOP_SEARCH_CBI_EMPLOYEE",
  API_ORG_SEARCH = "API_ORG_SEARCH",
  COLLECTIONS_URL_UPLOADS = "COLLECTIONS_URL_UPLOADS",
  COLLECTIONS_URL_CBI_EMPLOYEE = "COLLECTIONS_URL_CBI_EMPLOYEE",
  TECH_RELATED = "TECH_RELATED",
  NEWS_QUEUE = "NEWS_QUEUE",
  CUSTOMER_REQUEST = "CUSTOMER_REQUEST",
  OTHER = "OTHER",
  DESCRIPTION_GENERATION_SERVICE = "DESCRIPTION_GENERATION_SERVICE",
  DESCRIPTION_GENERATION_SERVICE_LOW_PRIORITY = "DESCRIPTION_GENERATION_SERVICE_LOW_PRIORITY",
  BUSINESS_MODEL_TAGS = "BUSINESS_MODEL_TAGS",
  UNRECOGNIZED = "UNRECOGNIZED",
}

/** The enum should match the ids in the database */
export enum DraftStatus {
  UNKNOWN_STATUS = "UNKNOWN_STATUS",
  READY_FOR_ENRICHMENT = "READY_FOR_ENRICHMENT",
  READY_FOR_QA = "READY_FOR_QA",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum FundingSourceType {
  UNKNOWN_FUNDING_SOURCE = "UNKNOWN_FUNDING_SOURCE",
  VC_SURVEY = "VC_SURVEY",
  FUNDINGS_BY_NEWS_QUEUE = "FUNDINGS_BY_NEWS_QUEUE",
  INVESTOR_PORTFOLIO_SCRAPER = "INVESTOR_PORTFOLIO_SCRAPER",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export enum GroupBy {
  /** GROUP_UNSPECIFIED - Will default to the group by RELATIONSHIP */
  GROUP_UNSPECIFIED = "GROUP_UNSPECIFIED",
  /** RELATIONSHIP - return the service relationship record for each relationship */
  RELATIONSHIP = "RELATIONSHIP",
  /** ENTITY - Return the service relationships for each entity */
  ENTITY = "ENTITY",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export interface Empty {
}

export interface SuccessStatus {
  success: boolean;
  errorMessage: string;
}

export interface TeamEntity {
  idTeamEntity: number;
  idTeam: number;
  idCreator: number;
  entityName: string;
  description?: string | undefined;
  url?: string | undefined;
  oldPrivateEntityId: number;
  oldPrivateEntityType: OldPrivateEntityType;
}

export interface TeamEntities {
  teamEntities: TeamEntity[];
}

export interface TeamEntitySearch {
  searchTerm: string;
  idTeam: number;
  limit?: number | undefined;
}

export interface SearchTerm {
  searchTerm: string;
  limit: number;
}

export interface GetEntityUrlUniquenessRequest {
  searchTerm: string;
  limit: number;
  checkPrimaryUrl: boolean;
  urls: string[];
  checkEntityDraft: boolean;
}

export interface SearchInfo {
  searchTerm: string;
  limit: number;
  entityType: IdType;
  searchVcFunds: boolean;
  serviceProvidersOnly: boolean;
  searchDrafts: boolean;
}

export interface EntitySearchResults {
  entitySearchResults: EntitySearchResult[];
}

export interface EntitySearchResult {
  idCbiEntity: number;
  name: string;
  url: string;
  description: string;
  altUrl: string;
  alias: string;
  entityType: IdType;
  idInvestor: number;
  idCompany: number;
  vcFund: string;
  idInvestorCategory: number;
  aliasType: IdAliasType;
  serviceProvider: ServiceProvider | undefined;
  idEntityDraft: string;
  foundInDraft: boolean;
  isDraftInvestor: boolean;
  draftStatus: DraftStatus;
}

export interface CbiEntityUrl {
  url: string;
}

export interface ScrapedDescription {
  entityDescription: { [key: string]: string };
}

export interface ScrapedDescription_EntityDescriptionEntry {
  key: string;
  value: string;
}

export interface IdCbiEntity {
  idCbiEntity: number;
  errorMessage: string;
}

export interface RepeatedIdCbiEntity {
  idCbiEntities: number[];
}

export interface GetEntitiesFundingsRequest {
  idCbiEntities: number[];
  /** if true valuation data should match what is in profiles.  If false it will only return what was entered in the admin */
  augmentValuationData: boolean;
  includeEntityInfo: boolean;
  idEntityDrafts: string[];
  includeFundingDrafts: boolean;
}

export interface GetEntitiesInvestmentsRequest {
  idCbiEntities: number[];
  /** if true valuation data should match what is in profiles.  If false it will only return what was entered in the admin */
  augmentValuationData: boolean;
  limit: number;
  offset: number;
  /** accepted sort columns: fundedCompanyName */
  sortColumn: string;
  sortOrder: SortOrder;
  includeNewsMention: boolean;
}

export interface Ids {
  ids: number[];
}

export interface InfoRequest {
  id: number;
  idType: IdType;
}

export interface GeneralCbiEntityInfo {
  entityNameInfo: EntityNameInfo | undefined;
  description: string;
  urlInfo: UrlInfo | undefined;
  email: string;
  status: EntityStatusInfo | undefined;
  ciks: number[];
  foundedYear: number;
  taxonomy: Taxonomy | undefined;
  addresses: Address[];
  idCbiEntity: number;
  idCompany: number;
  idInvestor: number;
  idParentEntities: number[];
  idChildEntities: number[];
  parentEntities: SimpleCbiEntityInfo[];
  childEntities: SimpleCbiEntityInfo[];
  totalFunding: number;
  vcBacked: boolean;
  idPrimaryCbiEntityType: CbiEntityType;
  isCompany: boolean;
  isInvestor: boolean;
  companySlug: string;
  investorSlug: string;
  idRootParentEntities: number[];
  rootParentEntities: SimpleCbiEntityInfo[];
  tagGroups: TagGroup[];
}

export interface SimpleCbiEntityInfo {
  idCbiEntity: number;
  idCompany: number;
  idInvestor: number;
  entityNameInfo: EntityNameInfo | undefined;
  idTeamEntity: number;
  primaryUrl: string;
  idPrimaryCbiEntityType: IdType;
  idEntityDraft: string;
}

export interface RepeatedInfoRequest {
  entities: InfoRequest[];
}

export interface GeneralCbiEntitiesInfo {
  entities: GeneralCbiEntityInfo[];
}

export interface SimpleCbiEntitiesInfo {
  entities: SimpleCbiEntityInfo[];
}

export interface SourceData {
  sourceType: SourceType;
  idSource: number;
}

export interface EntityInfo {
  entityNameInfo: EntityNameInfo | undefined;
  description: string;
  urlInfo: UrlInfo | undefined;
  email: string;
  status: EntityStatusInfo | undefined;
  ciks: number[];
  foundedYear: number;
  taxonomy: Taxonomy | undefined;
  addresses: Address[];
  idCbiEntity: number;
  createCompany: boolean;
  createInvestor: boolean;
  idPrimaryCbiEntityType: CbiEntityType;
  createEntityShell: boolean;
  idEntityDraft: string;
  idParentEntities: number[];
  idChildEntities: number[];
  parentEntities: SimpleCbiEntityInfo[];
  childEntities: SimpleCbiEntityInfo[];
  isCompany: boolean;
  isInvestor: boolean;
  idCompany: number;
  idInvestor: number;
  idRootParentEntities: number[];
  rootParentEntities: SimpleCbiEntityInfo[];
  tagGroups: TagGroup[];
}

export interface EntityNameInfo {
  primaryName: string;
  companyName: string;
  investorName: string;
  aliases: Alias[];
}

export interface Alias {
  idAlias: number;
  alias: string;
  aliasType: number;
  entityType: number;
  active: boolean;
}

export interface UrlInfo {
  primaryUrl: string;
  additionalUrls: AlternateUrls[];
}

export interface AlternateUrls {
  idUrl: number;
  url: string;
  entityType: IdType;
  active: boolean;
}

export interface EntityStatusInfo {
  idStatus: number;
  status: string;
}

export interface AllEntityStatusValues {
  allEntityStatusValues: EntityStatusInfo[];
}

export interface GetEntitiesMosaicScoresRequest {
  entities: InfoRequest[];
  deltaTimeSpanDays: number;
}

export interface MosaicScoresResponse {
  entityScores: MosaicScore[];
}

export interface MosaicScore {
  idCbiEntity: number;
  idCompany: number;
  overall: number;
  momentum: number;
  market: number;
  money: number;
  deltaOverall: number;
  deltaMomentum: number;
  deltaMarket: number;
  deltaMoney: number;
  management: number;
  deltaManagement: number;
}

export interface Taxonomy {
  sector: SectorValue | undefined;
  industry: IndustryValue | undefined;
  subIndustry: SubIndustryValue | undefined;
  category: CompanyCategoryValue | undefined;
  investorCategory: InvestorCategoryValue | undefined;
}

export interface SectorValue {
  idSector: number;
  name: string;
}

export interface IndustryValue {
  idIndustry: number;
  idSector: number;
  name: string;
}

export interface SubIndustryValue {
  idSubIndustry: number;
  idIndustry: number;
  name: string;
}

export interface CompanyCategoryValue {
  idCompanyCategory: number;
  name: string;
}

export interface InvestorCategoryValue {
  idInvestorCategory: number;
  name: string;
  idType: number;
}

/** Note: Taken directly from the company service! */
export interface Address {
  idAddress: number;
  addressName: string;
  hqFlag: number;
  street1: string;
  street2: string;
  cityInfo: CityInfo | undefined;
  stateInfo: StateInfo | undefined;
  zip: string;
  countryInfo: CountryInfo | undefined;
  idContinent: number;
  phone: string;
  fax: string;
  idGeoRegionNew: number;
  idType: IdType;
  continentName: string;
  latitude: string;
  longitude: string;
  regions: MsaRegion[];
}

export interface MsaRegion {
  idRegion: number;
  region: string;
}

export interface AllCityValues {
  cityInfo: CityInfo[];
}

export interface AllStateValues {
  stateInfo: StateInfo[];
}

export interface AllCountryValues {
  countryInfo: CountryInfo[];
}

export interface IdCountry {
  idCountry: number;
}

export interface IdSector {
  idSector: number;
}

export interface IdIndustry {
  idIndustry: number;
}

export interface AllSectorValues {
  taxonomyMap: TaxonomyMap[];
}

export interface TaxonomyMap {
  id: number;
  name: string;
  children: TaxonomyMap[];
}

export interface AllIndustryValues {
  allIndustryValues: IndustryValue[];
}

export interface AllSubIndustryValues {
  allSubIndustryValues: SubIndustryValue[];
}

export interface AllCompanyCategoryValues {
  allCompanyCategoryValues: CompanyCategoryValue[];
}

export interface AllInvestorCategoryValues {
  allInvestorCategoryValues: InvestorCategoryValue[];
}

export interface CityInfo {
  name: string;
  idCity: number;
  idState: number;
  idCountry: number;
  zip: string;
}

export interface SearchForCityInfo {
  name: string;
  idCity: number;
  idState: number;
  idCountry: number;
  limit: number;
}

export interface StateInfo {
  idState: number;
  idCountry: number;
  name: string;
}

export interface CountryInfo {
  idCountry: number;
  name: string;
  idContinent: number;
}

export interface Name {
  name: string;
}

export interface RoundInfo {
  idRound: number;
  name: string;
  idStatus: number;
  simplifiedRound: string;
  searchMapping: string;
  exitIndicator: boolean;
  fundingIndicator: boolean;
}

export interface AllRoundValues {
  roundInfo: RoundInfo[];
}

export interface PersonTitle {
  idPersonTitle: number;
  title: string;
  titleCode: string;
  titleRank: number;
}

export interface AllPersonTitles {
  personTitleMaps: { [key: number]: AllPersonTitles_PersonTitles };
}

export interface AllPersonTitles_PersonTitles {
  personTitles: PersonTitle[];
}

export interface AllPersonTitles_PersonTitleMapsEntry {
  key: number;
  value: AllPersonTitles_PersonTitles | undefined;
}

export interface AllAliasTypeValues {
  aliasTypeValues: AliasType[];
}

export interface AliasType {
  idAliasType: number;
  aliasType: string;
  description: string;
}

export interface EmploymentRelationships {
  employmentRelationships: EmploymentRelationship[];
}

export interface EmploymentRelationship {
  idInvPerson: number;
  idCbiEntity: number;
}

export interface IdInvestorPeople {
  idInvestorPeople: number[];
}

export interface InvestorPeople {
  investorPerson: InvestorPerson[];
}

export interface InvestorPerson {
  idInvestorPerson: number;
  fname: string;
  mname: string;
  lname: string;
  bio: string;
  title: string;
  email: string;
  idInvestor: number;
  name: string;
  investor: string;
  firms: FirmDetails[];
  twitter: string;
  facebook: string;
  linkedin: string;
  startDate: string;
  endDate: string;
}

export interface AllFundingInfo {
  idCbiEntity: number;
  idEntityDraft: string;
  fundingInfo: FundingInfo[];
  fundingDraftInfo: FundingInfo[];
  shouldUpdateCompanyStatus: boolean;
  entityName: string;
  description: string;
  status: EntityStatusInfo | undefined;
  url: string;
}

export interface RepeatedAllFundingInfo {
  fundingInfos: AllFundingInfo[];
}

export interface RepeatedAllInvestmentInfo {
  investmentInfos: AllFundingInfo[];
  numRecords: number;
}

/** Note: no need for IPO */
export interface FundingInfo {
  idCbiEntity: number;
  fundingDate: string;
  fundingRound: FundingRound | undefined;
  amount: number;
  minValuation: number;
  maxValuation: number;
  /** we only need investor name here. I am ok with only have name and id */
  investors: Investor[];
  mentions: MentionsInfo[];
  /** slightly different than how it works now, but I think this is fine (I think!) */
  boardOfDirectors: InvestorPerson[];
  valuation: number;
  ipo: number;
  fundingUnixTs: number;
  fundingIndicator: boolean;
  searchMapping: string;
  roundMapping: string;
  exitIndicator: boolean;
  fundedCompanyName: string;
  fundedCompanyUrl: string;
  equityIndicator: boolean;
  summaryJson: string;
  investorDisplayNew: InvestorDisplay[];
  investorDisplayFollowOn: InvestorDisplay[];
  divestitureFrom: DivestitureFrom | undefined;
  sources: FundingSourceType[];
}

export interface GetAllRoundValuesRequest {
  equityOnly: boolean;
}

export interface AllFundingRounds {
  idCbiEntity: number;
  fundingRound: FundingRound[];
}

export interface GetAllEntityFundingRoundsRequest {
  idCbiEntity: number;
  includeUnassociatedRounds: boolean;
  equityOnly: boolean;
}

export interface AllEntityFundingRounds {
  idCbiEntity: number;
  entityFundingRounds: FundingRound[];
  unassociatedFundingRounds: RoundInfo[];
}

export interface InvestorDisplay {
  idInvestor: number;
  name: string;
  idCbiEntity: number;
}

export interface Investor {
  idCbiEntity: number;
  idInvestor: number;
  name: string;
  leadInvestor: boolean;
  investorTypeId: number;
  investorType: string;
  idEntityDraft: string;
}

export interface DivestitureFrom {
  idCbiEntity: number;
  parentEntityName: string;
}

export interface FundingRound {
  idFunding: number;
  idRound: number;
  name: string;
}

export interface MentionsInfo {
  idFunding: number;
  url: string;
  /** cbi_data.funding_source's id_funding_source */
  idFundingSource: number;
  /** cbi_news.cb_company_news and cbi_data.funding_source id_source */
  idSource: number;
  title: string;
  sourceName: string;
  date: string;
  /** cbi_news.cb_company_news.id_language */
  idLanguage: number;
}

export interface IdFunding {
  idFunding: number;
}

export interface IdFundings {
  idFundings: number[];
  errorMessage: string;
  fundingMeta: FundingRound[];
}

export interface ProcessFundingRequest {
  idCbiEntity: number;
  fundingInfo: FundingInfo[];
  shouldUpdateCompanyStatus: boolean;
  itemsToDelete: FundingIdsToDelete | undefined;
  overrideValidation: boolean;
}

export interface ProcessFundingResponse {
  idCbiEntity: number;
  fundingInfo: FundingInfo[];
  errorMessage: string;
}

export interface FundingIdsToDelete {
  idFunding: number[];
  fundingInvestors: FundingInvestor[];
  fundingMentions: FundingMentions[];
  fundingBoardOfDirectors: FundingBoDPeople[];
  shouldUpdateCompanyStatus: boolean;
}

export interface FundingInvestor {
  idInvestor: number;
  idFunding: number;
}

export interface FundingMentions {
  idFundingSource: number;
  idFunding: number;
}

export interface FundingBoDPeople {
  idBoardOfDirector: number;
  idFunding: number;
}

export interface AllCompetitors {
  competitor: CompetitorInfo[];
}

export interface CompetitorInfo {
  idCompanyCompetitor: number;
  idCbiEntity: number;
  name: string;
  url: string;
  level: number;
  twoWay: boolean;
  idCbiEntityCompetitor: number;
  description: string;
  relevancyScore: number;
  rank: number;
  idCompetitorSources: CompetitorSource[];
  selfSourced: boolean;
}

export interface UpdateCompetitorsRequest {
  competitors: UpdateCompetitorRequest[];
}

export interface UpdateCompetitorRequest {
  idCbiEntity: number;
  idCbiEntityCompetitor: number;
  twoWay: boolean;
  source: string;
  selfSourced: boolean;
  relevancyScore: number;
  idSource: CompetitorSource;
  secondRelevancyScore: number;
}

export interface AllVcFundInfo {
  idCbiEntity: number;
  vcFundInfo: VcFundInfo[];
}

export interface IdVcFunds {
  idVcFunds: number[];
}

export interface VcFundInfo {
  idVcFund: number;
  fundName: string;
  sources: VcFundSource[];
  ciks: number[];
  amountRaised: number;
  amountTarget: number;
  startDate: string;
  closeDate: string;
  limitedPartners: LimitedPartner[];
  idCbiEntity: number;
  vintageYear: number;
  idVcFundType: number;
  idVcFundStatus: number;
  focuses: VcFundFocus[];
}

export interface LimitedPartner {
  idLimitedPartner: number;
  limitedPartner: string;
  idCbiEntity: number;
  commitment: number;
  idCommitmentType: number;
}

export interface VcFundSource {
  idVcFundSource: number;
  url: string;
}

export interface VcFundFocus {
  idVcFundFocusType: number;
  value: number;
  idVcFundFocus: number;
}

export interface AllLimitedPartnerInfo {
  limitedPartners: LimitedPartner[];
}

export interface VcFundCik {
  idVcFund: number;
  cik: number;
}

export interface VcFundLimitedPartner {
  idVcFund: number;
  idLimitedPartner: number;
}

export interface VcFundIdsToDelete {
  idVcFunds: number[];
  idVcFundSources: number[];
  vcFundCiks: VcFundCik[];
  vcFundLimitedPartners: VcFundLimitedPartner[];
  idVcFundFocuses: number[];
}

export interface AllCbiEntityEmployees {
  idCbiEntity: number;
  cbiEntityEmployeeInfo: CbiEntityEmployeeInfo[];
}

export interface CbiEntityEmployeeInfo {
  idPerson: number;
  email: string;
  fname: string;
  mname: string;
  lname: string;
  title: string;
  bio: string;
  linkedin: string;
  facebook: string;
  twitter: string;
  startDate: string;
  endDate: string;
  current: boolean;
}

export interface IdEmployees {
  idEmployee: number[];
}

export interface AllSalesMetrics {
  idCbiEntity: number;
  salesMetricInfo: SalesMetricInfo[];
}

export interface SalesMetricInfo {
  idMetric: number;
  maxSales: number;
  minSales: number;
  sources: SalesMetricSource[];
  date: string;
}

export interface IdSalesMetrics {
  idMetric: number[];
}

export interface SalesMetricSource {
  idSource: number;
  url: string;
}

export interface Slug {
  slug: string;
}

export interface PublicPage {
  publicPage: string;
}

export interface Success {
  success: boolean;
}

export interface UniquenessResponse {
  idCbiEntity: number;
  name: string;
  urlEntityMaps: { [key: string]: UniquenessResponse_ListEntities };
  idEntityDraft: string;
}

export interface UniquenessResponse_ListEntities {
  cbiEntities: SimpleCbiEntityInfo[];
}

export interface UniquenessResponse_UrlEntityMapsEntry {
  key: string;
  value: UniquenessResponse_ListEntities | undefined;
}

export interface IdAddresses {
  idCbiEntity: number;
  idAddresses: number[];
}

export interface TeamEntityUpdateInfo {
  idTeamEntity: number;
  entityName?: string | undefined;
  description?: string | undefined;
  url?: string | undefined;
}

export interface TeamEntitiesUpdateInfo {
  teamEntities: TeamEntityUpdateInfo[];
}

export interface IdTeamEntities {
  idTeamEntities: number[];
}

export interface Ciks {
  ciks: Cik[];
  checkDrafts: boolean;
}

export interface Cik {
  idCompany: number;
  cik: number;
}

export interface SalesMetricIdsToDelete {
  idMetrics: number[];
  idSources: IdSalesMetricSource[];
}

export interface IdSalesMetricSource {
  idMetric: number;
  idSource: number;
}

export interface ProcessSalesMetricRequest {
  idCbiEntity: number;
  salesMetricInfo: SalesMetricInfo[];
  itemsToDelete: SalesMetricIdsToDelete | undefined;
}

export interface EntityIds {
  idCbiEntity: number;
  idCompany: number;
  idInvestor: number;
  idPrimaryEntityType: CbiEntityType;
}

export interface ParentChildRelationships {
  parentChildRelationships: ParentChildEntities[];
}

export interface ParentChildEntities {
  parent: InfoRequest | undefined;
  child: InfoRequest | undefined;
}

export interface GetCompanyAndInvestorIdsRequest {
  entityNameAndUrls: EntityNameAndUrl[];
  getInactive: boolean;
  allowNoUrl?: boolean | undefined;
}

export interface EntityNameAndUrl {
  name: string;
  url: string;
}

export interface GetCompanyAndInvestorIdsResponse {
  entityIds: EntityIds[];
  entitiesNotMatch: EntityNameAndUrl[];
}

export interface GetEntitiesInvestorExitsRequest {
  idCbiEntities: number[];
  startDate: string;
  endDate: string;
  aggregateOnly: boolean;
  /** if true valuation data should match what is in profiles.  If false it will only return what was entered in the admin */
  augmentValuationData: boolean;
  /** if true then return non empty MentionsInfo in response. */
  includeNewsMention: boolean;
}

export interface GetEntitiesInvestorExitsResponse {
  entityExits: EntityExitInfo[];
}

export interface GetEntitiesDivestituresRequest {
  idCbiEntities: number[];
  idInvestors: number[];
  includeNewsMention: boolean;
}

export interface GetEntitiesDivestituresResponse {
  entityDivestitures: EntityExitInfo[];
}

export interface EntityExitInfo {
  idCbiEntity: number;
  numExits: number;
  exits: ExitInfo[];
  mentions: MentionsInfo[];
}

export interface ExitInfo {
  companyIdCbiEntity: number;
  companyIdCompany: number;
  companyName: string;
  acquirerIdCbiEntity: number;
  acquirerIdInvestor: number;
  acquirerName: string;
  acquirerUrl: string;
  amount: number;
  idRound: number;
  round: string;
  roundMapping: string;
  tsFunding: string;
  valuation: number;
  minValuation: number;
  maxValuation: number;
  searchMapping: string;
  idFunding: number;
}

export interface CreateAcquisitionRequest {
  idInvestor: number;
  idCompany: number;
}

export interface CreateAcquisitionResponse {
  idInvestor: number;
  idCompany: number;
}

export interface DeleteAcquisitionRequest {
  idCompany: number;
  idInvestor: number;
}

export interface GetEntitiesAcquirersRequest {
  idCbiEntities: number[];
}

export interface GetEntitiesAcquirersResponse {
  acquirers: Acquirer[];
}

export interface Acquirer {
  id: number;
  name: string;
  idAcquiree: number;
}

export interface InvestorPeopleDetailsResponse {
  investorPeopleDetails: InvestorPersonDetails[];
}

export interface InvestorPersonDetails {
  idInvPerson: number;
  firmDetails: FirmDetails[];
  boardDetails: BoardDetails[];
  roundDetails: RoundDetails[];
}

export interface FirmDetails {
  firm: string;
  idInvPerson: number;
  startDate: string;
  title: string;
  idCbiEntity: number;
  idInvestor: number;
  endDate: string;
  current: boolean;
  idPersonTitle: number;
}

export interface BoardDetails {
  company: string;
  current: boolean;
  startDate: string;
  endDate: string;
  idInvPerson: number;
  idCbiEntityInvestment: number;
  title: string;
  url: string;
  idPersonTitle: number;
}

export interface RoundDetails {
  idCbiEntityInvestment: number;
  investorRounds: InvestorRound[];
  allRounds: FundingRound[];
}

export interface InvestorRound {
  idFunding: number;
  idInvPerson: number;
  idCbiEntityInvestment: number;
  round: string;
}

export interface BoardMemberFundingRoundRequest {
  boardMemberFundingRounds: BoardMemberFundingRound[];
}

export interface BoardMemberFundingRound {
  idInvPerson: number;
  idFunding: number;
}

export interface AddFirmsToInvestorPeopleRequest {
  firms: FirmDetails[];
}

export interface RemoveFirmsFromInvestorPeopleRequest {
  firmsToDelete: FirmDetails[];
}

export interface MapIdResponse {
  companyMap: { [key: number]: number };
  investorMap: { [key: number]: number };
}

export interface MapIdResponse_CompanyMapEntry {
  key: number;
  value: number;
}

export interface MapIdResponse_InvestorMapEntry {
  key: number;
  value: number;
}

export interface XigniteOrg {
  symbol: string;
  exchange: string;
  idFactSetIssuer: string;
  idCbiEntity: number;
}

export interface GetIdCbiEntitiesForXigniteOrgsRequest {
  xigniteOrgs: XigniteOrg[];
}

export interface GetIdCbiEntitiesForXigniteOrgsResponse {
  xigniteOrgs: XigniteOrg[];
}

export interface GetEntityIdsMappingRequest {
  idType: IdType;
  ids: number[];
}

export interface GetEntityIdsMappingResponse {
  entityIds: EntityIds[];
}

export interface GetSimpleCbiEntitiesInfoBySlugRequest {
  slugs: string[];
  pageSize: number;
  pageToken: string;
}

export interface GetSimpleCbiEntitiesInfoBySlugResponse {
  entities: SimpleCbiEntityInfo[];
  nextPageToken: string;
}

export interface CalculateNumPageViewsRequest {
  idCbiEntities: number[];
}

export interface CalculateNumPageViewsResponse {
  numPageViewsList: NumPageViews[];
}

export interface NumPageViews {
  idCbiEntity: number;
  numPageViews: number;
}

export interface GenerateAddressInfoRequest {
  idCountry: number;
  zip: string;
}

export interface GenerateAddressInfoResponse {
  stateInfo: StateInfo | undefined;
  cityInfo: CityInfo | undefined;
}

/** to be deprecated - DO NOT USE */
export interface Entity {
  idCbiEntity: number;
  idCompany: number;
  idInvestor: number;
  name: string;
  url: string;
  idPrimaryCbiEntityType: IdType;
}

export interface GetCompanyAndInvestorMatchesRequest {
  entityNames: string[];
  getInactive: boolean;
  urlOrName?: boolean | undefined;
  nameOnly?: boolean | undefined;
  entityUrls: string[];
  entityTypes: IdType[];
}

export interface GetCompanyAndInvestorMatchesResponse {
  entities: Entity[];
}

export interface EntityPerson {
  idInvPerson: number;
  firstName: string;
  middleName: string;
  lastName: string;
  bio: string;
  bioSource: string;
  linkedin: string;
  twitter: string;
  workExperience: WorkExperience[];
  boardAssociations: BoardAssociation[];
  education: Education[];
  tsUpdated: string;
  email: string;
  /** if multiple sources they will be here (cbi_data.entity_person_source) */
  idSources: number[];
}

export interface WorkExperience {
  idCbiEntity: number;
  entityName: string;
  title: PersonTitle | undefined;
  function: string;
  current: boolean;
  startDate: string;
  endDate: string;
  idWorkExperience: number;
  idCompany: number;
  idInvestor: number;
  entityUrl: string;
}

export interface BoardAssociation {
  idCbiEntity: number;
  entityName: string;
  title: PersonTitle | undefined;
  current: boolean;
  startDate: string;
  endDate: string;
  fundingRounds: FundingRound[];
  idBoardAssociation: number;
  idSource: number;
  idCompany: number;
  idInvestor: number;
}

export interface School {
  idSchool: number;
  school: string;
}

export interface Degree {
  idDegree: number;
  degree: string;
}

export interface FieldOfStudy {
  idFieldOfStudy: number;
  fieldOfStudy: string;
}

export interface Education {
  school: School | undefined;
  degree: Degree | undefined;
  fieldOfStudy: FieldOfStudy | undefined;
  idEntityPersonEducation: number;
  graduationYear: number;
}

export interface AllDegrees {
  degrees: Degree[];
}

export interface AllFieldsOfStudy {
  fieldsOfStudy: FieldOfStudy[];
}

export interface ListEntityPeopleRequest {
  nameFilter: string;
  idCbiEntityFilter: number[];
  idTitlesFilter: number[];
  currentStatusFilter?: boolean | undefined;
  formerStatusFilter?: boolean | undefined;
  limit: number;
  offset: number;
  view: ListEntityPeopleRequest_ListEntityPeopleView;
  idInvPersonFilter: number[];
  lastUpdateTime: string;
}

export enum ListEntityPeopleRequest_ListEntityPeopleView {
  /** LIST_ENTITY_PEOPLE_VIEW_UNSPECIFIED - Will default to the BASIC view */
  LIST_ENTITY_PEOPLE_VIEW_UNSPECIFIED = "LIST_ENTITY_PEOPLE_VIEW_UNSPECIFIED",
  /** BASIC - All matched person details but only work experience and board memberships that matched the filters */
  BASIC = "BASIC",
  /** FULL - All matched person details and entire work experience and board memberships */
  FULL = "FULL",
  /** ID_INV_PERSON_ONLY - Only returns matching id_investor_person */
  ID_INV_PERSON_ONLY = "ID_INV_PERSON_ONLY",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export interface ListEntityPeopleResponse {
  entityPeople: EntityPerson[];
  totalResults: number;
}

export interface UpdateEntityPersonRequest {
  person: EntityPerson | undefined;
  workExperienceIdsToDelete: number[];
  boardAssociationsIdsToDelete: number[];
  fundingRoundIdsToDelete: number[];
  entityPersonEducationIdsToDelete: number[];
}

export interface DetectDuplicateLinkedinUrlsRequest {
  urls: string[];
}

export interface DetectDuplicateLinkedinUrlsResponse {
  linkedinToIdInvPeople: { [key: string]: DetectDuplicateLinkedinUrlsResponse_IdInvPeople };
}

export interface DetectDuplicateLinkedinUrlsResponse_IdInvPeople {
  idInvPeople: number[];
}

export interface DetectDuplicateLinkedinUrlsResponse_LinkedinToIdInvPeopleEntry {
  key: string;
  value: DetectDuplicateLinkedinUrlsResponse_IdInvPeople | undefined;
}

export interface GetEntityPersonRequest {
  idInvPerson: number;
}

export interface BatchGetEntityPeopleRequest {
  idInvPeople: number[];
  skipBasicInfo: boolean;
  skipCurrentWorkExperience: boolean;
  skipPastWorkExperience: boolean;
  skipBoardAssociations: boolean;
  skipEducation: boolean;
}

export interface BatchGetEntityPeopleResponse {
  people: EntityPerson[];
}

export interface DeleteEntityPeopleRequest {
  idInvPeople: number[];
}

export interface SearchSchoolsResponse {
  schools: School[];
}

export interface GetEntitiesRevenuesRequest {
  idCbiEntities: number[];
}

export interface GetEntitiesRevenuesResponse {
  entitiesRevenueData: EntityRevenueData[];
}

export interface EntityRevenueData {
  idCbiEntity: number;
  entityRevenues: EntityRevenue[];
}

export interface EntityRevenue {
  revenueDate: string;
  revenueMinAmount: number;
  revenueMaxAmount: number;
  revenueSources: string[];
  revenueSourceType: string;
  revenueTimePeriod: string;
}

export interface ServiceProvider {
  idCbiEntity: number;
  idServiceProvider: number;
  serviceProviderType: ServiceProviderType | undefined;
  entityName: string;
  entityUrl: string;
  description: string;
  logoUrl: string;
}

export interface ServiceProviderType {
  idServiceProviderType: number;
  serviceProviderType: string;
}

export interface ServiceProviderRelationship {
  idServiceProviderRelationship: number;
  idCbiEntityServiceProvider: number;
  idCbiEntityClient: number;
  entityNameServiceProvider: string;
  entityNameClient: string;
  associatedTransaction: ServiceProviderTransaction | undefined;
  source: string;
  idServiceProvider: number;
  serviceProviderRelationshipServiceTypes: ServiceProviderRelationshipServiceType[];
  serviceProviderType: ServiceProviderType | undefined;
  idCompanyServiceProvider: number;
  idCompanyClient: number;
  idInvestorClient: number;
}

export interface ServiceProviderRelationshipServiceType {
  idServiceProviderRelationshipServiceType: number;
  serviceType: ServiceType | undefined;
}

export interface ServiceType {
  idServiceType: number;
  serviceType: string;
}

export interface ServiceProviderTransaction {
  idCbiEntity: number;
  entityName: string;
  fundingRound: FundingRound | undefined;
  isSell: boolean;
  exitIndicator: boolean;
}

export interface DeleteServiceProviderRequest {
  idServiceProvider: number;
}

export interface DeleteServiceProviderRelationshipRequest {
  idServiceProviderRelationship: number;
}

export interface GetServiceProviderInfoRequest {
  idCbiEntity: number;
  idServiceProvider: number;
}

export interface GetServiceProviderInfoResponse {
  serviceProvider: ServiceProvider | undefined;
  hasClient: boolean;
  /** DEPRECATED, use GetEntityClientRelationships */
  clients: ServiceProviderRelationship[];
  /** DEPRECATED, use GetEntityServiceProviderRelationships */
  serviceProviders: ServiceProviderRelationship[];
}

export interface Filter {
  field: string;
  value: string;
}

export interface GetEntityClientRelationshipsRequest {
  idCbiEntity: number;
  limit: number;
  offset: number;
  groupBy: GroupBy;
  /** accepted filter fields: associatedRounds, clientName, providerType, serviceType */
  filters: Filter[];
  /** accepted sort columns: associatedRounds, clientName, providerType, serviceType, idServiceProviderRelationship, serviceProviderName */
  sortColumn: string;
  sortOrder: SortOrder;
}

export interface GetEntityClientRelationshipsResponse {
  clients: ServiceProviderRelationship[];
  totalCount: number;
}

export interface GetEntityServiceProviderRelationshipsRequest {
  idCbiEntity: number;
  limit: number;
  offset: number;
  groupBy: GroupBy;
  sortColumn: string;
  sortOrder: SortOrder;
}

export interface GetEntityServiceProviderRelationshipsResponse {
  serviceProviders: ServiceProviderRelationship[];
  totalCount: number;
}

export interface ListServiceProviderTypesRequest {
}

export interface ListServiceProviderTypesResponse {
  serviceProviderTypes: ServiceProviderType[];
}

export interface ListServiceTypesRequest {
}

export interface ListServiceTypesResponse {
  serviceTypeMaps: { [key: number]: ListServiceTypesResponse_ServiceTypes };
}

export interface ListServiceTypesResponse_ServiceTypes {
  serviceTypes: ServiceType[];
}

export interface ListServiceTypesResponse_ServiceTypeMapsEntry {
  key: number;
  value: ListServiceTypesResponse_ServiceTypes | undefined;
}

export interface GetOutdatedSlugsRequest {
  companyBatchSize: number;
  investorBatchSize: number;
}

export interface GetOutdatedSlugsResponse {
  slugs: OutdatedSlug[];
}

export interface OutdatedSlug {
  id: number;
  name: string;
  slug: string;
  entity_type: number;
}

export interface UpdateSlugsRequest {
  companySlugs: UpdatedSlug[];
  investorSlugs: UpdatedSlug[];
}

export interface UpdatedSlug {
  id: number;
  slug: string;
  updated: boolean;
  entity_type: number;
}

export interface CreateEntityDraftRequest {
  entityInfo: EntityInfo | undefined;
  entityDraftInfo:
    | EntityDraftInfo
    | undefined;
  /** tmp field only */
  useDraft: boolean;
  /** when createEntityShellDraft is true, we only require the primary url */
  createEntityShellDraft: boolean;
}

export interface CreateEntityDraftResponse {
  idEntityDraft: string;
}

export interface BatchCreateEntityDraftsRequest {
  entityDraftsInfo: EntityDraftInfo[];
  createEntityShellDraft: boolean;
}

export interface BatchCreateEntityDraftsResponse {
  idEntityDrafts: string[];
}

export interface UpdateEntityDraftRequest {
  entityDraftInfo: EntityDraftInfo | undefined;
  skipValidation: boolean;
}

export interface UpdateEntityDraftResponse {
  successStatus: SuccessStatus | undefined;
  idEntityDraft: string;
  version: number;
  isDraftValid: boolean;
}

export interface ListEntityDraftsRequest {
  idEntityDrafts: string[];
  idCbiEntities: number[];
  generalFilters: GeneralFilter[];
  entityDraftFilters: EntityFilter[];
  limit: number;
  offset: number;
  idCbiEntitiesToExclude: number[];
  idEntityDraftsToExclude: string[];
}

export interface EntityDraftInfo {
  entityInfo: EntityInfo | undefined;
  entityDraftMetadata: EntityDraftMetadata | undefined;
}

export interface EntityDraftMetadata {
  version: number;
  lastUpdated: string;
  fieldCount: number;
  lastModifiedUser: User | undefined;
  sourceData: SourceData[];
  draftStatus: DraftStatus;
  creationDate: string;
  creationUserId: number;
}

export interface ListEntityDraftsResponse {
  entityDraftInfo: EntityDraftInfo[];
}

export interface DiscardEntityDraftsRequest {
  entityDrafts: EntityDraftInfo[];
  idCbiEntities: number[];
}

export interface DiscardEntityDraftsResponse {
  successStatus: SuccessStatus | undefined;
}

export interface PublishEntityDraftRequest {
  idEntityDraft: string;
  entityDraftInfo: EntityDraftInfo | undefined;
  idCbiEntity: number;
  isCorrect?: boolean | undefined;
}

export interface PublishEntityDraftResponse {
  successStatus: SuccessStatus | undefined;
  idCbiEntity: number;
}

export interface HasPendingEntityDraftsRequest {
  idCbiEntity?: number | undefined;
  idEntityDraft?: string | undefined;
}

export interface HasPendingEntityDraftsResponse {
  hasPendingDrafts: boolean;
}

export interface CreateEntityNoteRequest {
  idCbiEntity?: number | undefined;
  idEntityDraft?: string | undefined;
  note: string;
}

export interface CreateEntityNoteResponse {
  successStatus: SuccessStatus | undefined;
}

export interface User {
  idUser: number;
  firstName: string;
  lastName: string;
}

export interface EntityNote {
  idEntityNote: number;
  note: string;
  user: User | undefined;
  tsInsert: string;
}

export interface ListEntityNotesRequest {
  idCbiEntity?: number | undefined;
  idEntityDraft?: string | undefined;
}

export interface ListEntityNotesResponse {
  entityNotes: EntityNote[];
}

export interface DeleteEntityNotesRequest {
  idCbiEntities: number[];
  idEntityDrafts: string[];
  idEntityNotes: number[];
}

export interface DeleteEntityNotesResponse {
  successStatus: SuccessStatus | undefined;
}

export interface GetDraftCountsRequest {
  publishedDraftCountStartDate: string;
  sourceTypes: number[];
}

export interface GetDraftCountsResponse {
  pendingDraftCount: number;
  publishedDraftCount: number;
  enrichmentDraftCount: number;
  qaDraftCount: number;
}

export interface GetDraftersRequest {
  startDate: string;
  endDate: string;
  pendingRecordsOnly?: boolean | undefined;
  publishedRecordsOnly?: boolean | undefined;
  qaAndPublishedRecordsOnly?: boolean | undefined;
}

export interface GetDraftersResponse {
  users: User[];
  idUserToAdminRoles: { [key: number]: UserAdminRoles };
}

export interface GetDraftersResponse_IdUserToAdminRolesEntry {
  key: number;
  value: UserAdminRoles | undefined;
}

export interface UserAdminRoles {
  idUser: number;
  adminRoles: AdminRole[];
}

export interface AdminRole {
  idAdminRole: number;
  adminRole: string;
}

export interface GeneralFilter {
  drafterFilter?: DrafterFilter | undefined;
  dateFilter?: DateFilter | undefined;
  statusFilter?: StatusFilter | undefined;
  sourceTypeFilter?: SourceTypeFilter | undefined;
}

export interface EntityFilter {
}

export interface StatusFilter {
  draftStatuses: DraftStatus[];
}

export interface SourceTypeFilter {
  sourceTypes: SourceType[];
}

export interface DrafterFilter {
  idDrafters: number[];
}

export interface DateFilter {
  startDate: string;
  endDate: string;
}

export interface GetEntityInfoRequest {
  idEntityDraft: string;
  idCbiEntity: number;
}

export interface GetEntityInfoResponse {
  entityInfo: EntityInfo | undefined;
  entityDraftInfo: EntityDraftInfo | undefined;
  lastPublishedDate: string;
  userCanMarkDraftCorrect: boolean;
}

export interface EntityDraftSourceType {
  idEntityDraftSourceType: number;
  entityDraftSourceType: string;
}

export interface AllEntityDraftSourceTypes {
  entityDraftSourceTypes: EntityDraftSourceType[];
}

export interface EntityDraftStatusType {
  idEntityDraftStatusType: number;
  entityDraftStatusType: string;
}

export interface AllEntityDraftStatusTypes {
  entityDraftStatusTypes: EntityDraftStatusType[];
}

export interface GetDeletedCompetitorsRequest {
  idCbiEntities: number[];
}

export interface GenerateAuditReportRequest {
  idUsers: number[];
  startDate: string;
  endDate: string;
  fieldsReport: boolean;
  summaryReport: boolean;
}

export interface GenerateAuditReportResponse {
  userAuditInfo: UserAuditInfo[];
}

export interface UserAuditInfo {
  idUser: number;
  fullUserName: string;
  email: string;
  summaryAuditInfo: SummaryAuditInfo | undefined;
  fieldsAuditInfo: FieldsAuditInfo[];
}

export interface FieldsAuditInfo {
  draftName: string;
  publishedName: string;
  draftURL: string;
  publishedURL: string;
  draftStatus: string;
  publishedStatus: string;
  draftDescription: string;
  publishedDescription: string;
  draftPrimaryEntityType: string;
  publishedPrimaryEntityType: string;
  draftCountry: string;
  publishedCountry: string;
  draftPostal: string;
  publishedPostal: string;
  draftState: string;
  publishedState: string;
  draftCity: string;
  publishedCity: string;
  draftStreet1: string;
  publishedStreet1: string;
  draftStreet2: string;
  publishedStreet2: string;
  draftPhone: string;
  publishedPhone: string;
  draftFoundedYear: string;
  publishedFoundedYear: string;
  draftSector: string;
  publishedSector: string;
  draftIndustry: string;
  publishedIndustry: string;
  draftSubIndustry: string;
  publishedSubIndustry: string;
  draftAliases: string;
  publishedAliases: string;
  draftAltURL: string;
  publishedAltURL: string;
  draftInvestorCategory: string;
  publishedInvestorCategory: string;
  extraDraftAddresses: string[];
  extraPublishedAddresses: string[];
  lastUpdated: string;
  idCbiEntity: number;
  draftCIKS: string;
  publishedCIKS: string;
  draftHqFlag: string;
  publishedHqFlag: string;
  url: string;
}

export interface SummaryAuditInfo {
  correctDraftsCount: number;
  incorrectDraftsCount: number;
  percentMarkedCorrect: number;
  totalDraftsSubmitted: number;
  totalDraftsReviewed: number;
}

export interface ListTagGroupsRequest {
  /** not required, by default will return all tag groups */
  idTagGroup: number;
  excludeTag: boolean;
}

export interface ListTagGroupsResponse {
  tagGroups: TagGroup[];
}

export interface TagGroup {
  idTagGroup: number;
  tagGroup: string;
  tags: Tag[];
}

export interface Tag {
  idTag: number;
  tag: string;
}
