import { UseMutationResult, useMutation } from 'react-query';
import {
  UpdateGatedTrialApprovalStatusRequest,
  IdUser,
} from '@cbinsights/userv2service/userv2service';

import { request } from 'client/modules/common/utils/request';

const updateGatedTrialApprovalStatus = async (
  body: Partial<UpdateGatedTrialApprovalStatusRequest>
) =>
  request({
    url: '/service/userv2service/UpdateGatedTrialApprovalStatus',
    body,
  }).then((r) => r.body as IdUser);

export const useUpdateGatedTrialApprovalStatusMutation = (): UseMutationResult<
  IdUser,
  unknown,
  Partial<UpdateGatedTrialApprovalStatusRequest>,
  unknown
> => useMutation(updateGatedTrialApprovalStatus);
