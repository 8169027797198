import React, { ReactElement } from 'react';
import { Col, Divider, Row, Typography } from 'antd';
import moment from 'moment';
import { ChangePackageModal } from '../ChangePackageModal';

type Props = {
  packageId: number;
  packageName: string;
  packageStart?: string;
  packageEnd?: string;
  refetchTeam?: () => void;
};

export const PackageSetting = ({
  packageId,
  packageName,
  packageStart,
  packageEnd,
  refetchTeam,
}: Props): ReactElement => {
  return (
    <Col
      xl={{ push: 2, span: 6 }}
      md={{ push: 2, span: 20 }}
      xs={{ push: 1, span: 22 }}
    >
      <Typography.Title level={4} className="!font-medium mt-4">
        Package Setting
      </Typography.Title>
      <Divider className="border-t-2 border-black border-solid" />
      <Row justify="space-between" align="bottom">
        <Col span="8">
          <Typography.Title level={5} className="!font-medium">
            Team Package
          </Typography.Title>
        </Col>
        <Col span="8" className="text-center">
          {packageName || 'No Package Selected'}
        </Col>
      </Row>
      <Row justify="space-between" align="bottom">
        <Col span="8">
          <Typography.Title level={5} className="!font-medium">
            Start Date
          </Typography.Title>
        </Col>

        <Col span="8" className="text-center">
          {packageStart && moment(packageStart).format('MM-DD-YYYY')}
        </Col>
      </Row>
      <Row justify="space-between" align="bottom">
        <Col span="8">
          <Typography.Title level={5} className="!font-medium">
            End Date
          </Typography.Title>
        </Col>

        <Col span="8" className="text-center">
          {packageEnd && moment(packageEnd).format('MM-DD-YYYY')}
        </Col>
      </Row>
      <Row justify="center">
        <ChangePackageModal
          refetchTeam={refetchTeam}
          packageName={packageName}
          packageID={packageId}
          packageStart={packageStart}
          packageEnd={packageEnd}
        />
      </Row>
    </Col>
  );
};

export default PackageSetting;
