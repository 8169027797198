import React, { ReactElement } from 'react';
import { Col, Divider, Row, Switch, Tooltip, Typography, message } from 'antd';
import { GetTeamResponse } from '@cbinsights/adminservice/adminservice';
import { DomainWhiteList } from '../DomainWhitelist';
import { useToggleTeamAddon } from '../../hooks/useToggleTeamAddon';
import { FEATURE_IDS } from '../../constants/features';
import { AddonDetail } from '../../hooks/useTeamForm';
import MFA from '../MFA';
import LinkBasedSignup from '../TeamSettings/LinkBasedSignup';

type Props = {
  mfaStatus?: boolean;
  domainWhitelist?: string;
  ssoStatus?: AddonDetail;
  lbsStatus?: GetTeamResponse['signup_code'];
  refetchTeam: () => void;
  teamId: number;
};

export const TeamSettings = ({
  mfaStatus,
  domainWhitelist,
  ssoStatus,
  lbsStatus,
  refetchTeam,
  teamId,
}: Props): ReactElement => {
  const { submit, toggleTeamAddonQuery } = useToggleTeamAddon();

  const toggleSSOStatus = (checked: boolean) => {
    submit({
      enabled: checked,
      idFeature: FEATURE_IDS.SSO,
      idTeam: teamId,
    })
      .then(() => {
        message.success("Success! The team's SSO status has been updated.");
        refetchTeam();
      })
      .catch(() => {
        message.error(
          "Error! Failed to update the team's SSO status. Please try again later."
        );
        // revert mutation update
        refetchTeam();
      });
  };

  return (
    <Col
      xl={{ push: 2, span: 7 }}
      md={{ push: 2, span: 20 }}
      xs={{ push: 1, span: 22 }}
    >
      <Typography.Title level={4} className="!font-medium mt-4">
        Team Setting
      </Typography.Title>
      <Divider className="border-t-2 border-black border-solid" />
      <Row justify="space-between">
        <Col span="12">
          <Typography.Title level={5} className="!font-medium">
            <Tooltip title="Controls the MFA requirement when users sign in. If enabled, users will be sent a code via email upon sign in. Users must provide that code to sign in.">
              Multi-Factor Authentication :
            </Tooltip>
          </Typography.Title>
        </Col>
        <Col span="12" className="text-right pr-2">
          <MFA
            mfaStatus={mfaStatus}
            teamId={teamId}
            refetchTeam={refetchTeam}
          />
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span="12">
          <Typography.Title level={5} className="!font-medium">
            <Tooltip title="Only enables the SSO tab in the client Account Settings screen. Does not affect the clients ability to sign in through SSO.">
              SSO Add-on:
            </Tooltip>
          </Typography.Title>
        </Col>
        <Col span="12" className="text-right pr-2">
          <Tooltip
            title={
              ssoStatus.includedInPackage
                ? 'This add-on is already part of your package and it cannot be disabled'
                : ''
            }
          >
            <Switch
              loading={toggleTeamAddonQuery.isLoading}
              checked={ssoStatus.enabled || ssoStatus.includedInPackage}
              onChange={toggleSSOStatus}
              disabled={
                toggleTeamAddonQuery.isLoading || ssoStatus.includedInPackage
              }
            />
          </Tooltip>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span="12">
          <Typography.Title level={5} className="!font-medium">
            <Tooltip title="Enables a team to add new users to their team via a custom link.">
              Link Based Signup :
            </Tooltip>
          </Typography.Title>
        </Col>
        <Col span="12" style={{ textAlign: 'right' }} className="pr-2">
          <LinkBasedSignup
            refetchTeam={refetchTeam}
            teamId={teamId}
            lbsStatus={lbsStatus}
            domainWhitelist={domainWhitelist}
          />
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span="12">
          <Typography.Title level={5} className="!font-medium">
            <Tooltip title="Sets the allowed email domains for users on this team. When signing up via Link Based Signup or Invitation, the user's email must match one of these domains.">
              Domain Whitelist :
            </Tooltip>
          </Typography.Title>
        </Col>
        <Col span="12" className="flex justify-end items-center">
          <DomainWhiteList
            domainWhitelist={domainWhitelist}
            refetchTeam={refetchTeam}
            teamId={teamId}
          />
        </Col>
      </Row>
    </Col>
  );
};
