import { UseQueryResult, useQuery } from 'react-query';
import {
  ListGatedTrialUsersRequest,
  ListGatedTrialUsersResponse,
  GatedTrialUser,
  GatedTrialStatus,
} from '@cbinsights/userv2service/userv2service';

import { request } from 'client/modules/common/utils/request';

const listGatedTrialUsers = async (body: Partial<ListGatedTrialUsersRequest>) =>
  request({
    url: '/service/userv2service/ListGatedTrialUsers',
    body,
  }).then((r) => r.body as ListGatedTrialUsersResponse);

export const LIMIT = 100;

type ListGatedTrialUserQueryReturn = {
  users: GatedTrialUser[];
  offset: number;
  statusFilter: GatedTrialStatus[];
};

const useListGatedTrialUserQuery = (
  offset: number,
  statusFilter: GatedTrialStatus[]
): UseQueryResult<ListGatedTrialUserQueryReturn, Error> =>
  useQuery<
    ListGatedTrialUsersResponse,
    Error,
    ListGatedTrialUserQueryReturn,
    ['ListGatedTrialUsers', number, GatedTrialStatus[], string]
  >(
    ['ListGatedTrialUsers', offset, statusFilter, statusFilter.join(',')],
    () =>
      listGatedTrialUsers({
        offset,
        limit: LIMIT,
        approval_status_filter: statusFilter,
      }),
    {
      notifyOnChangeProps: ['data', 'isFetching'], // prevent rerenders, only update selected props
      select: (response) => ({
        users: response.users,
        offset,
        statusFilter,
      }),
    }
  );

export default useListGatedTrialUserQuery;
