import { Button, Space, Table } from 'antd';

import { AntdSingleCheckboxField } from 'client/modules/common/antDesign/form/fields/AntdCheckboxField';
import { ColumnsType } from 'antd/lib/table';
import { EntitySearch } from 'client/modules/cbi-entity/components/cbi-entity/EntitySearch';
import { EntitySearchResult } from '@cbinsights/cbientityservice/cbientityservice';
import { ExportOutlined } from '@ant-design/icons';
import { useFormikContext } from 'formik';
import { MarketEntityInReviewStatus } from '@cbinsights/espmatrixservice/espmatrixservice';
import getProfileURL, {
  COMPANY_ENTITY,
  INVESTOR_ENTITY,
} from 'client/modules/cbi-entity/utils/getProfileUrl';
import { DeleteCompanyButton } from './DeleteCompanyButton';
import {
  MarketCompany,
  MarketFormProps,
  MarketFormValues,
} from '../hooks/useGetMarketDetailsValues';

export const MarketCompaniesTable = () => {
  const { values, setFieldValue } = useFormikContext<MarketFormValues>();
  const dataSource = values[MarketFormProps.Companies];

  const columns: ColumnsType<MarketCompany> = [
    {
      title: 'IdCbiEntity',
      dataIndex: 'idCbiEntity',
      key: 'idCbiEntity',
      width: 120,
    },
    {
      title: 'Consider for ESP?',
      dataIndex: 'ConsiderForEsp',
      key: 'considerForEsp',
      align: 'center',
      width: 155,
      render(_, record, index) {
        return (
          <Space align="center">
            <AntdSingleCheckboxField
              name={`${MarketFormProps.Companies}[${index}].considerForScoring`}
            />
          </Space>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'entityName',
      key: 'entityName',
    },
    {
      title: 'Url',
      dataIndex: 'url',
      key: 'url',
      render(data) {
        return (
          <a
            className="text-blue-600"
            href={`//${data}`}
            target="_blank"
            rel="noreferrer"
          >
            {data}
          </a>
        );
      },
    },
    {
      title: '',
      key: 'actions',
      width: 200,
      render(_, record, index) {
        return (
          <Space>
            <a
              target="_blank"
              title="Open Profile"
              href={`//${getProfileURL(
                record?.idCompany
                  ? {
                      idCompanyOrIdInvestor: record?.idCompany,
                      type: COMPANY_ENTITY,
                    }
                  : {
                      idCompanyOrIdInvestor: record?.idInvestor,
                      type: INVESTOR_ENTITY,
                    }
              )}`}
              rel="noreferrer"
            >
              <Button type="link">
                Open Profile <ExportOutlined />
              </Button>
            </a>

            {!record.status && (
              <DeleteCompanyButton
                index={index}
                url={record.url}
                name={record.entityName}
              />
            )}
          </Space>
        );
      },
    },
  ];

  const addCompany = (_, entity: EntitySearchResult) => {
    if (dataSource.some((el) => el.idCbiEntity === entity.idCbiEntity)) {
      return;
    }

    setFieldValue(MarketFormProps.Companies, [
      {
        idCbiEntity: entity.idCbiEntity,
        entityName: entity.name,
        url: entity.url,
        status: MarketEntityInReviewStatus.MARKET_ENTITY_IN_REVIEW_ADDED,
        considerForScoring: true,
        idCompany: entity.idCompany,
        idInvestor: entity.idInvestor,
      },
      ...dataSource,
    ]);
  };

  return (
    <>
      <EntitySearch
        showCreateEntity={false}
        onSelection={addCompany}
        placeholder="Search an entity to add it into the list"
      />
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        rowClassName={(record) => {
          if (
            record.status ===
            MarketEntityInReviewStatus.MARKET_ENTITY_IN_REVIEW_DELETED
          ) {
            return 'bg-red-200';
          }
          if (
            record.status ===
            MarketEntityInReviewStatus.MARKET_ENTITY_IN_REVIEW_ADDED
          ) {
            return 'bg-yellow-200';
          }
          return '';
        }}
      />
    </>
  );
};
