import { DraftLogoInfo, LogoInfo } from '@cbinsights/logoservice/logoservice';
import { Form, Space } from 'antd';
import { AntdInputField } from 'client/modules/common/antDesign/form/fields/AntdInputField';
import { AntdOpenUrlIcon } from 'client/modules/common/antDesign/partials/AntdOpenUrlIcon';
import { FieldErrorAndWarningMessage } from 'client/modules/common/antDesign/form/fields/FieldErrorAndWarningMessage';
import React, { useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { LoadingOutlined } from '@ant-design/icons';
import { useFormikContext } from 'formik';
import {
  CBIEntityFormModel,
  CBISectionForm,
  SectionFieldName,
} from './CBISectionFormLayout';

import { CompanyLogoVisor } from './CompanyLogoVisor';
import { EditCompanyLogoButton } from './EditCompanyLogoButton';
import {
  GeneralInfoFieldNames,
  GeneralInfoFormStatus,
} from '../../services/general-info/helpers/mapGeneralCbiEntityToForm';
import { useGetCbiProfileLink } from './hooks/useGetCbiProfileLink';
import { useGetEntityLogos } from '../../services/general-info/hooks/useGetEntityLogos';

export const CBISectionHeaderForm = ({
  values,
  formIsLoading,
  status,
}: CBISectionForm<
  CBIEntityFormModel,
  { formIsLoading: boolean },
  GeneralInfoFormStatus
>) => {
  const { dirty } = useFormikContext();
  const idCbiEntity = values[SectionFieldName.IdCbiEntity];
  const idEntityDraft = !values[SectionFieldName.IdEntityDraft]
    ? undefined
    : values[SectionFieldName.IdEntityDraft];
  const idCompany = values[SectionFieldName.Meta]?.idCompany;
  const idInvestor = values[SectionFieldName.Meta]?.idInvestor;
  const queryClient = useQueryClient();
  const entityLogo = useGetEntityLogos({
    enabled: true,
    idEntityDraft,
    idCompany,
    idInvestor,
  });

  const prev = useRef(null);
  const [isLogoLoading, setLogoLoading] = useState(false);

  useEffect(() => {
    if (formIsLoading) {
      setLogoLoading(true);
    }
  }, [formIsLoading]);

  useEffect(() => {
    if (
      (prev.current !== null && prev.current !== idEntityDraft) ||
      idCbiEntity
    ) {
      queryClient.invalidateQueries(['GetEntityLogo']);
    }

    prev.current = idEntityDraft || idCbiEntity;
  }, [idEntityDraft, idCbiEntity]);

  useEffect(() => {
    if (!entityLogo.isStale && entityLogo.isSuccess && isLogoLoading) {
      setLogoLoading(false);
    }
  }, [entityLogo.isStale, entityLogo.isSuccess]);

  const refreshLogo = () => {
    queryClient.invalidateQueries(['GetEntityLogo']);
  };

  const { profileLink } = useGetCbiProfileLink(values[SectionFieldName.Meta]);
  const validate = !dirty
    ? undefined
    : (value) =>
        !value && status[GeneralInfoFieldNames.SubmitType] !== 'DELETE_DRAFTS'
          ? 'Name is required'
          : '';

  return (
    <Form.Item>
      <Space size="small">
        <div className="relative" style={{ minWidth: '312px' }}>
          <AntdInputField
            name={SectionFieldName.EntityName}
            className="py-0"
            suffix={
              !values[SectionFieldName.Meta]?.isNew && (
                <AntdOpenUrlIcon url={profileLink} description={`  Profile`} />
              )
            }
            required={!values[SectionFieldName.IdEntityDraft]}
            validateOnBlur
            placeholder="Entity Name"
            validate={validate}
          />
          <FieldErrorAndWarningMessage name={SectionFieldName.EntityName} />
        </div>
        {/* new entity draft page doesn't get/post logo */}
        {(idCbiEntity || idEntityDraft) && !(idCbiEntity && idEntityDraft) && (
          <Space align="center" className="flex" size="small">
            {!formIsLoading && !isLogoLoading ? (
              <CompanyLogoVisor
                idEntity={idEntityDraft ?? idCbiEntity}
                logoInfo={entityLogo.data ?? ({} as LogoInfo | DraftLogoInfo)}
                onDeleteLogoSuccessfully={refreshLogo}
              />
            ) : (
              <div className="flex justify-center w-[135px]">
                <LoadingOutlined />
              </div>
            )}
            <EditCompanyLogoButton
              idCompany={idCompany}
              idInvestor={idInvestor}
              idEntityDraft={idEntityDraft}
              entityName={values[SectionFieldName.EntityName]}
              entityUrl={values[SectionFieldName.Url]}
              onEditLogoSuccessively={refreshLogo}
            />
          </Space>
        )}
      </Space>
    </Form.Item>
  );
};
