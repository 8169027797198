import EditableText from 'client/component-library/EditableText/EditableText';
import React from 'react';
import { message } from 'antd';
import { useUpdateTeam } from '../../hooks/useUpdateTeam';

type Props = {
  teamId: number;
  domainWhitelist?: string;
  refetchTeam?: () => void;
};

export const DomainWhiteList: React.FC<Props> = ({
  domainWhitelist,
  refetchTeam,
  teamId,
}: Props) => {
  const { onSubmit } = useUpdateTeam(refetchTeam);

  const extractDomain = (url) => {
    let domain = url.trim().replace(/^(https?):\/\//, '');
    // Remove subdomain
    domain = domain.replace(/^www\./, '');
    // Remove paths and query parameters
    domain = domain.replace(/\/([^/]+).*$/, '');
    domain = domain.toLowerCase();
    return domain;
  };

  const handleSave = async (newDomainlist) => {
    let domains = newDomainlist
      .split(',')
      .map((domain) => extractDomain(domain));
    domains = [...new Set(domains)];
    try {
      await onSubmit({ domain_list: domains.join(',') }, teamId);
      message.success("Success! The team's domain list has been updated.");
      refetchTeam();
    } catch (e) {
      message.error(
        "Error! Failed to update the team's domain list. Please try again later."
      );
    }
  };

  const validateDomain = (domains) => {
    const urlArray = domains.split(',');
    const filteredUrlArray = urlArray.filter((url) => url.trim() !== '');
    const expression =
      /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;
    const regex = new RegExp(expression);

    for (let i = 0; i < filteredUrlArray.length; i++) {
      if (!filteredUrlArray[i].trim().match(regex)) {
        return 'Invalid domain. One or more domains are invalid.';
      }
    }
    return '';
  };

  return (
    <EditableText
      text={domainWhitelist}
      onSave={handleSave}
      validation={validateDomain}
    />
  );
};
