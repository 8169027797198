import React, { ReactElement } from 'react';
import { Button, Space, Tooltip } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

type PageNavigationProps = {
  from: number;
  to: number;
  onPageChange: (offset: number) => void;
  isBackDisabled?: boolean;
  isForwardDisabled?: boolean;
};

export const PageNavigation = ({
  from,
  to,
  onPageChange,
  isBackDisabled = false,
  isForwardDisabled = false,
}: PageNavigationProps): ReactElement => (
  <div className="flex justify-end mb-4">
    <div>
      <span className="pr-2 text-sm">
        Showing {from} to {to}
      </span>
      <Space>
        <Tooltip title="Previous">
          <Button
            type="text"
            size="small"
            onClick={() => onPageChange(-1)}
            icon={<LeftOutlined />}
            disabled={isBackDisabled}
          />
        </Tooltip>
        <Tooltip title="Next">
          <Button
            type="text"
            size="small"
            onClick={() => onPageChange(1)}
            icon={<RightOutlined />}
            disabled={isForwardDisabled}
          />
        </Tooltip>
      </Space>
    </div>
  </div>
);
