import React, { ReactElement, useCallback, useMemo } from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import {
  GatedTrialStatus,
  GatedTrialUser,
} from '@cbinsights/userv2service/userv2service';

import { ActionCell, StatusCell } from './UserTableDataRow';

type UserTableProps = {
  data?: GatedTrialUser[];
  currentStatusFilter?: GatedTrialStatus[];
  isLoading: boolean;
  onUpdateStatusFilter: (statusFilters: GatedTrialStatus[]) => void;
};

export const UserTable = ({
  data = [],
  currentStatusFilter,
  isLoading,
  onUpdateStatusFilter,
}: UserTableProps): ReactElement => {
  const columns: ColumnsType<GatedTrialUser> = useMemo(
    () => [
      {
        key: 'email',
        title: 'Email',
        dataIndex: 'email',
        className: 'email-col',
      },
      {
        key: 'approval_status',
        title: 'Approval Status',
        dataIndex: 'approval_status',
        filterMultiple: true,
        filteredValue: currentStatusFilter,
        filters: [
          {
            text: GatedTrialStatus.PENDING,
            value: GatedTrialStatus.PENDING,
          },
          {
            text: GatedTrialStatus.DENIED,
            value: GatedTrialStatus.DENIED,
          },
          {
            text: GatedTrialStatus.APPROVED,
            value: GatedTrialStatus.APPROVED,
          },
        ],
        className: 'approval_status-col',
        render: (_, { approval_status: status }) => (
          <StatusCell status={status} />
        ),
      },
      {
        key: 'approval_action',
        title: 'Approval Action',
        dataIndex: '',
        className: 'approval_action-col',
        render: (_, user) => <ActionCell user={user} />,
      },
      {
        key: 'source',
        title: 'Source',
        dataIndex: 'source',
      },
      {
        key: 'first_name',
        title: 'First Name',
        dataIndex: 'first_name',
        className: 'first_name-col',
      },
      {
        key: 'last_name',
        title: 'Last Name',
        dataIndex: 'last_name',
        className: 'last_name-col',
      },
      {
        key: 'phone',
        title: 'Phone Number',
        dataIndex: 'phone',
        className: 'phone-col',
      },
      {
        key: 'company',
        title: 'Company',
        dataIndex: 'company',
        className: 'company-col',
      },
      {
        key: 'title',
        title: 'Title',
        dataIndex: 'title',
        className: 'title-col',
      },
      {
        key: 'ts_created',
        title: 'Created On',
        dataIndex: 'ts_created',
        className: 'ts_created-col',
      },
      {
        key: 'ts_updated',
        title: 'Updated On',
        dataIndex: 'ts_updated',
        className: 'ts_updated-col',
      },
    ],
    [currentStatusFilter]
  );

  const handleTableChange = useCallback(
    (pagination, filters) => {
      if ('approval_status' in filters) {
        onUpdateStatusFilter(filters.approval_status || []);
      }
    },
    [onUpdateStatusFilter]
  );

  return (
    <Table
      columns={columns}
      dataSource={data}
      loading={isLoading}
      rowKey={(user) => user.email}
      pagination={false}
      onChange={handleTableChange}
      sticky
    />
  );
};

export default UserTable;
