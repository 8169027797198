import moment from 'moment';
import { useMutation } from 'react-query';
import { createTeam } from '../services/api/CreateTeam';

export const useCreateTeamForm = () => {
  const createTeamMutation = useMutation(createTeam);
  const onSubmit = (teamName, packageId, packageStart, packageEnd) => {
    const startDate = moment(packageStart).format('YYYY-MM-DD HH:mm:ss');
    const endDate = moment(packageEnd).format('YYYY-MM-DD HH:mm:ss');
    return createTeamMutation.mutateAsync({
      name: teamName,
      id_package: packageId,
      package_start: startDate,
      package_end: endDate,
    });
  };

  return {
    onSubmit,
  };
};
