import React from 'react';
import { RouteObject } from 'react-router-dom';

import ListUsersPage from './ListUsersPage';

export const gatedTrialRoutes: RouteObject = {
  path: 'gated-trial',
  element: (
    <React.Suspense fallback={undefined}>
      <ListUsersPage />
    </React.Suspense>
  ),
};
